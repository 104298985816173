import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {FullCalendarComponent} from "@fullcalendar/angular";
import {CompanyApiService} from "../services/company/company-api.service";
import {Storage} from "@ionic/storage";
import {Constants} from "../services/constants";
import {AuthService} from "../services/auth.service";
import {AppointmentApiService} from "../services/company/appointment-api.service";
import {AdminApiService} from "../services/admin/admin-api.service";
import {ScheduleApiService} from "../services/company/schedule-api.service";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import {filter, tap, toArray} from "rxjs/operators";
import esLocale from '@fullcalendar/core/locales/es';
import {ModalController, ToastController} from "@ionic/angular";
import {CreateModalComponent} from "./create-modal/create-modal.component";
import {EditModalComponent} from "./edit-modal/edit-modal.component";
import {CustomerApiService} from "../services/customer/customer-api.service";
import * as moment from 'moment';
import {WarningModalComponent} from "./warning-modal/warning-modal.component";
import {InfoModalComponent} from "./info-modal/info-modal.component";
import {ContactBookApiService} from 'src/app/services/company/contact-book-api.service';
import {DataService} from 'src/app/services/company/data.service';
import {Router} from '@angular/router';
import { Company } from 'src/app/models/admin/Company';
import {HeaderModule} from 'src/app/modules/header/header.module';
import {MobileHeaderModule} from '../modules/mobile-header/mobile-header.module';
import { OriginConnectionPosition } from '@angular/cdk/overlay';

@Component({
  selector: 'app-my-calendar',
  templateUrl: './my-calendar.component.html',
  styleUrls: ['./my-calendar.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class MyCalendarComponent {
  @ViewChild('calendar') calendar:FullCalendarComponent;
  @Input('contact') contact;
  @Input('contacts') contacts;
  calendarApi;
  companyId;
  company:Company[];
  isDayViewSelected:boolean=false;
  options;
  chargeReady = false;

  events: any[]=[];
  resources:any[]=[];

  appointments:any;
  customerAppointments:any;
  priorities:any;
  schedules:any;
  close_appointments: any[]=[];
  company_coords : any;
  date: Date;
  
  filterResultDataSet = [];

  rainbow = ['#EEEAFF','#E2F5FF','#DBEEE2','#FFEAEA'];
  borderRainbow = ['#6C4491', '#3F7CAC', '#009543','#FF3E96'];

  maxHour;
  minHour;

  interval: any;

  loading : boolean = false;
  
  notFound = false;

  public phone = '';
  public search = true;
  public allowDeployLegend = false;

  constructor(
      private adminApiService : AdminApiService,
      private contactBookApiService: ContactBookApiService,
      private companyApiService: CompanyApiService,
      private storage: Storage,
      private constants: Constants,
      private authService:AuthService,
      private appointmentApiService:AppointmentApiService,
      private scheduleApiService:ScheduleApiService,
      private modalController:ModalController,
      private customerApiService:CustomerApiService,
      private toastController:ToastController,
      private dataService: DataService,
      private router: Router
  ) {

  }

  ngOnInit() {
    /*Creamos las opciones del calendario */
    this.options = {
      // Tema
      theme: 'standart',
      // Lo que aparece arriba
      header: {
        // A la izquierda arriba
        left: 'prev,next today',
        // El centro
        center: 'title',
        // La derecha para ver las diferentes vistas
        right: 'dayGridMonth,timeGridWeek,resourceTimeGridDay '
      },
      titleFormat: { // will produce something like "Tuesday, September 18, 2018"
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        weekday: 'long'
      },
      customButtons: {
        day: {
          text: 'Día',
          click: function() {
            this.isDayViewSelected=true;
          }.bind(this)
        }
      },views:{
            dayGridMonth:{
                eventLimit:4
            }
          },
      // Idioma español
      locale: esLocale,
      now : function(){return moment().add(moment().utcOffset(),"minutes").toISOString();},
      // Plugins que se necesitan
      plugins: [dayGridPlugin, timeGridPlugin,interactionPlugin,resourceTimeGridPlugin ]
    };

    if (window.innerWidth < 992) {
      this.options['header'] = {
        left: 'prev,next today dayGridMonth,timeGridWeek,resourceTimeGridDay',
        // El centro
        right: 'title'
      }
    }

    this.authService.getMyCompanyIdWell().then(companyId=> {
      this.companyId=companyId;
      this.getSchedules(companyId);
      this.interval = setInterval( () => this.getSchedules(companyId), 15000);
    });
  }

  onResize(event) {
    if (event.target.innerWidth < 992) {
      this.options['header'] = {
        left: 'prev,next today dayGridMonth,timeGridWeek,resourceTimeGridDay',
        // El centro
        right: 'title'
      }
    }else{
      this.options['header'] = {
        // A la izquierda arriba
        left: 'prev,next today',
        // El centro
        center: 'title',
        // La derecha para ver las diferentes vistas
        right: 'dayGridMonth,timeGridWeek,resourceTimeGridDay '
      }
    }
  }

  ngAfterViewInit() {
    this.calendarApi=this.calendar.getApi();
  }

  getAppointments(companyId){
    this.appointmentApiService.getMyAppointments(companyId).pipe(tap(appointments=>{
      let events=[];
      for (let [key, appointment] of Object.entries(appointments)){
        let event=this.createEvent(appointment);
        events.push(event);
      }

      this.events=events;


    })).subscribe(appointments=>{
      this.appointments=appointments;

      for(var i=0; i<appointments.length; i++){
        var now = new Date();
        var appointment_time = new Date(appointments[i].start);

        if(appointment_time > now){
          const milliseconds = Math.abs(appointment_time.getTime() - now.getTime());
          const hours = milliseconds / 36e5;

          if(hours <= 1){
            var appointment = appointments[i];

            this.storage.get(this.constants.COORDS_COMPANY).then(async val => {
              this.company_coords = val;
              var origin_coords = {
                "longitude" : appointment['customer']['user']['longitude'],
                "latitude" : appointment['customer']['user']['latitude']
              };

              if(val != null){
                var data = {
                  "originLatitude" : origin_coords.latitude,
                  "originLongitude" : origin_coords.longitude,
                  "destinationLatitude" : this.company_coords.latitude,
                  "destinationLongitude" : this.company_coords.longitude
                }
                await this.appointmentApiService.checkLocation(data).subscribe(result =>{
                  appointment['distance'] = result;
                  this.close_appointments.push(appointment);
                  // this.calendarApi.next();
                  // this.calendarApi.prev();
                }, erro => {console.log(erro)});
              }
            });
          }
        }
      }
      this.loading = false;
      //Si un usuario está seleccionado, pintamos las zonas ocupadas de rojo
      if(this.contact!=null){
        this.getBackgroundAppointments();
        // clearInterval(this.interval);
      }
    })
    this.getCompany(companyId);
  }

  getCompany(companyId){
    this.adminApiService.getACompany(companyId).subscribe(result => {
      if(result.open_hour_1 == null || result.close_hour_1 == null){
        this.minHour = '08:00';
        this.maxHour = '21:00';
      }else{
        this.minHour = result.open_hour_1.substring(11,16);
        if(result.close_hour_2){
          this.maxHour = result.close_hour_2.substring(11,16);
        }else {
          this.maxHour = result.close_hour_1.substring(11,16);
        }
      }
      
      this.company = result;
      this.chargeReady = true; 
    }, erro => {
      if(erro.status == 401){
      }
    });
  }

  getBackgroundAppointments(){
    console.log('Llega al get background')
    this.loading=true;

    //Customer Appointments
    this.customerApiService.getCustomerAppointments(this.contact).subscribe(appointments=>{
      this.customerAppointments=appointments;
      let appointment;
      let events=[];
      for(let [key, appointment] of Object.entries(appointments)){
        //Comprobar que la cita no está cancelada
        if(appointment['cancelation_date']) continue;
        events.push(this.createEvent(appointment,true));
      }

      events=events.concat(this.events);
      this.events=events;

      //Customer priority times
      this.customerApiService.getPriorityTime(this.contact).subscribe(result => {

        this.priorities=result;

        let priority;
        let events=[];
        for(priority of this.priorities){
          events.push(this.createRecursiveEvent(priority))
        }


        events=events.concat(this.events);
        this.events=events;

        this.loading = false;

      }, err => {
        if (err.status == 404) {
          this.presentToast('Todavía no ha dado de alta ningún cliente.');
        } else {
          this.presentToast('Se ha producido un error. Inténtelo de nuevo más tarde.');
        }
        this.loading = false;
      });
    })
  }

  getSchedules(companyId){
    console.log("llega");
    this.loading = true;
    this.companyId = companyId;
    this.scheduleApiService.getMySchedules(companyId).pipe(tap(schedules=>{
      let resources=[];
      for(let [key, schedule] of Object.entries(schedules)){
        if(schedule.leaving_date==null){
          resources.push(this.createResource(schedule));
        }
      }
      this.resources=resources;

    }), filter((schedule:any)=>schedule.leaving_date==null)).subscribe(schedules=>{
      this.schedules=schedules;
      this.getAppointments(companyId);
    });
  }

  eventResize(event){
    this.showEditModal(event);
  }

  dateClick(event){

  }

  select(event){
    if(event.allDay){ //No se ha seleccionado hora
      this.calendarApi.changeView('resourceTimeGridDay',event.start);
    }else{ //Se selecciona hora
      if(event.resource==undefined){ //No se selecciona agenda
        this.calendarApi.changeView('resourceTimeGridDay',event.start);
      }else{ //Se selecciona agenda
        let date=moment(event.start,"MMM DD/MM/YYYY HH:mm:ss").toISOString();
        let now=moment().add(moment().utcOffset(),"minutes").toISOString();
        if(date<now) { //Si la fecha ya ha pasado
          this.presentToast("No se pueden añadir citas en fechas pasadas","danger");
          return;
        }

        const availableRange = this.isDateRangeAvailable(event.start, event.end);
        if(availableRange == true){

          this.date = new Date(event.startStr);
          this.date.setMinutes(this.date.getMinutes() + this.company['appointment_default_length']);

          this.showCreateModal(event.startStr,this.date.toISOString(),event.resource._resource);
        }else{
          const message = availableRange == "priority" ? "El cliente ha indicado que no quiere citas a esta hora, ¿seguro " +
              "que quieres añadir una cita a esta hora?" : "El cliente ya tiene una cita para esa hora, ¿seguro que quieres " +
              "añadir una cita a la misma hora?";
          this.showWarningModal("Conflicto", message, _=>{
            this.showCreateModal(event.startStr,event.endStr,event.resource._resource);
          });
        }
      }
    }

  }

  showWarningModal(title, message,action){
    let modal=this.modalController.create({
      component: WarningModalComponent,
      cssClass:["warning-modal"],
      componentProps: {
        title:title,
        message:message
      }
    })
    modal.then(modal=>{
      modal.present();
      modal.onDidDismiss().then(data=>{
        if(!data.data.dismiss){
          action();
        }
      });
    });
    return modal;
  }

  isDateRangeAvailable(start,end){
    start=moment(start,"MMM DD/MM/YYYY HH:mm:ss");
    let day=start.day();
    start=start.toISOString();
    end=moment(end,"MMM DD/MM/YYYY HH:mm:ss").toISOString();


    if (this.customerAppointments != null){
      let customerAppointment;
      for(customerAppointment of this.customerAppointments){

        const appStart=moment(customerAppointment.start,"YYYY-MM-DD HH:mm:ss").add(moment().utcOffset(),"minutes").toISOString();
        const appEnd=moment(customerAppointment.end,"YYYY-MM-DD HH:mm:ss").add(moment().utcOffset(),"minutes").toISOString();

        if(start >= appStart && start < appEnd) return "conflict";
        else if (end > appStart && end <= appEnd) return "conflict";
        else if (start <= appStart && end >= appEnd) return "conflict";
      }
    }

    const startTime=moment(start).utc(false).format("HH:mm:ss");
    const endTime=moment(end).utc(false).format("HH:mm:ss");

    if(this.priorities != null){
      let priorityTime;
      for(priorityTime of this.priorities){
        if(!priorityTime.blocked) continue;
        else if(priorityTime.week_day != day) continue;
        else if(startTime >= priorityTime.start_hour && start < priorityTime.finish_hour) return "priority";
        else if (endTime > priorityTime.start_hour && endTime <= priorityTime.finish_hour) return "priority";
        else if (startTime <= priorityTime.start_hour && endTime >= priorityTime.finish_hour) return "priority";
      }
    }

    return true;
  }

  presentToast(msg: string, color: string = "success") {
    this.toastController.create({
      color,
      message: msg,
      duration: 4000
    }).then(toast=>toast.present());
  }

  eventRender(info) {
    // let event = info.event._def;

    // if(this.close_appointments.indexOf(event.extendedProps.appointment) != -1){
    //   let element = document.createElement("div");
    //   var index = this.close_appointments.indexOf(event.extendedProps.appointment);

    //   var now = moment();
    //   var last_geolocation_update = moment(this.close_appointments[index]['customer']['user']['last_geolocation_update']);

    //   var minutes = now.diff(last_geolocation_update, 'minutes')- 119 ;

    //   let textNode = document.createTextNode('El cliente está a '+this.close_appointments[index]['distance'].distancia+' hace '+minutes+' minutos');
    //   element.appendChild(textNode);
    //   info.el.appendChild(element);
    // }

    // if(event.rendering != 'background' && event.extendedProps.appointment.cancelation_date){
    //   info.el.classList.add("deleted");
    // }
  }


  eventClick(event: any) {
    this.showInfoModal(event.event.extendedProps.appointment);
  }

  eventDragStop(event: any) {
    let date=moment(event.event._instance.range.start).toISOString();
    let now=moment().add(moment().utcOffset(),"minutes").toISOString();
    if(date<now) { //Si la fecha ya ha pasado
      this.presentToast("No se pueden mover citas a fechas pasadas","danger");
      event.revert();
      return;
    }
    this.showEditModal(event);
  }

  createEvent(appointment, isBackground=false){
    if(isBackground){
      return {
        start: appointment.start,
        end: appointment.end,
        rendering: 'background',
        color:'red'
      };
    }else{
      const backgroundColor=this.rainbow[this.getResourceIndex(appointment.schedule_id_dexga)%this.rainbow.length];
      const borderColor=this.borderRainbow[this.getResourceIndex(appointment.schedule_id_dexga)%this.borderRainbow.length];

      let date=moment(appointment.start).toISOString();
      let now=moment().add(moment().utcOffset(),"minutes").toISOString();
      const editable = date>now;
      let event = {
        'id':appointment.appointment_id_dexga,
        'editable':editable,
        'title':appointment.alias + ' | ' + appointment.customer?.phone,
        'date':appointment.start,
        'start':appointment.start,
        'end':appointment.end,
        'resourceEditable':editable,
        'resourceId':appointment.schedule_id_dexga,
        'appointment':appointment,
        'color':backgroundColor,
        'borderColor':borderColor,
        'textColor':borderColor
      };

      if(appointment.cancelation_date){ //Appointment cancelled
        event['color'] = "#eee";
        event['borderColor'] = backgroundColor;
        event['textColor']="black";
        event['resourceEditable'] = false;
        event['editable'] = false;
        event['className'] = 'deleted';
      }

      if(!appointment.confirmation_date_company){
        event['color'] = "#F2F2FF";
        event['borderColor'] = "black";
        event['textColor']="black";
        event['resourceEditable'] = false;
        event['editable'] = false;
      }

      return event;
    }
  }

  createRecursiveEvent(priority){
    let color = priority.blocked ? 'red' : 'green';
    return {
      'id':priority.priority_time_id_dexga,
      'startTime':priority.start_hour,
      'endTime':priority.finish_hour,
      'daysOfWeek':[priority.week_day],
      'rendering':"background",
      "color": color
    };
  }

  getResourceIndex(schedule_id){
    let resource;
    let i=0;
    for(resource of this.resources){
      if(resource.id==schedule_id) return i;
      i++;
    }
    return -1;
  }

  createResource(schedule){
    let resource={
      'id':schedule.schedule_id_dexga,
      'title':schedule.name,
      'position' : schedule.position
    }

    return resource;
  }

  showCreateModal(dateStart,dateEnd=null,schedule=null){
      let modal=this.modalController.create({
        component: CreateModalComponent,
        cssClass: 'customModalCssAppointment',
        componentProps: {
          dateStart:dateStart,
          dateEnd:dateEnd,
          schedule:schedule,
          schedules:this.resources,
          contacts:this.contacts,
          companyId:this.companyId,
          contact:this.contact
        }
      })
      modal.then(modal=>{
        modal.present();
        modal.onDidDismiss().then(data=>{
          if(data.data==undefined || data.data.dismissed) return;
          let appointment=data.data.appointment;
          this.events=this.events.concat(this.createEvent(appointment));
        });
      });
      return modal;
  }

  showEditModal(event){
    let oldEvent=event.oldEvent? event.oldEvent:event.prevEvent;
    let modal=this.modalController.create({
      component: EditModalComponent,
      componentProps: {
        eventNew:{event:event.event._def, range:event.event._instance.range},
        eventOld:{event:oldEvent._def, range:oldEvent._instance.range},
        schedules:this.resources,
        companyId:this.companyId
      }
    })
    modal.then(modal=>{
      modal.present();
      modal.onDidDismiss().then(data=>{
        if(data.data!=undefined && data.data.accept){

          let events=[]
          let modifiedAppointment=data.data.data;
          for(let event of this.events){
            if(event.id==modifiedAppointment.appointment_id_dexga){
              events.push(this.createEvent(modifiedAppointment));
            }else{
              events.push(event);
            }
          }
          this.events=events;
        }else{
          event.revert();
        }
      });
    });
    return modal;
  }

  showInfoModal(event){
    let modal=this.modalController.create({
      component: InfoModalComponent,
      cssClass: 'customModalCssInsertContact',
      componentProps: {
        event:event
      }
    })
    modal.then(modal=>{
      modal.present();
      modal.onDidDismiss().then(data=>{
        if(data.data==undefined) return;
        if(data.data?.action == 'cancel'){
          let auxEvent;
          let newList=[];
          for(auxEvent of this.events){
            if(auxEvent.id == event.appointment_id_dexga){
              auxEvent = auxEvent.appointment;
              auxEvent.cancelation_date = new Date();
              auxEvent.cancelation_reason = data.data.cancelation_reason;

              newList.push(this.createEvent(auxEvent));
              continue;
            }
            newList.push(auxEvent);
          }
          this.events = newList;

          this.presentToast("Cita anulada con éxito", 'success');
        }else if(data.data?.action == 'delete'){
          let auxEvent;
          let newList=[];
          for(auxEvent of this.events){
            if(auxEvent.id == event.appointment_id_dexga){
              continue;
            }
            newList.push(auxEvent);
          }
          this.events = newList;

          this.presentToast("Cita eliminada con éxito", 'success');
        }
      });
    });
    return modal;
  }

  //Buscar cliente
  filterResult() {
    if(this.phone.length < 1){
        this.filterResultDataSet = [];
    }else{
        this.getContacts();
    }
  }

  getContacts() {
    this.search = true;
    this.storage.get(this.constants.COMPANY).then(companyId => {
        this.contactBookApiService.getFilteredContacts(companyId, this.phone).subscribe(result => {
                this.processClients(result); // Añade campo de dias desde/hasta la ultima cita del cliente
                this.contacts = result.reverse(); // Cambiar orden. De los 30 más recientes, mostrar antes los de citas menos recientes
                this.filterResultDataSet = result;
                this.notFound = false;
            },
            err => {
                if (err.status === 404) {
                    this.notFound = true;
                }
                this.search = false;
            },
            () => {
                this.search = false;
            });
      });
  }

  clearResults() {
      this.contacts = null;
  }

  processClients(contacts) {
    for (const contact of contacts) {
        contact.tiempoUltimaCita = this.diferenciaFechas(contact.last_appointment);
    }
}

  diferenciaFechas(inicial) {
    // let difference = new Date().getTime() - new Date(inicial).getTime();
    if (inicial == null) {
        return '---';
    }
    const fechaInicio = new Date(inicial).getTime();
    const fechaFin = new Date().getTime();

    const diff = fechaFin - fechaInicio;
    const dias: any = (diff / (1000 * 60 * 60 * 24)).toFixed();

    if (dias >= 1) {
        return `Hace  ${dias} días`;
    } else if (dias <= -1) {
        return `Dentro de  ${-dias} días`;
    } else {
        const horas: any = (diff / (1000 * 60 * 60)).toFixed();
        if (horas > 0) {
            return `Hace  ${horas} horas`;
        } else {
            return `Dentro de  ${-horas} horas`;
        }
    }
  }

  goToAvailability(contact) {
    this.dataService.setData(contact.contact_book_id, contact);
    this.router.navigateByUrl('tienda/cita/disponibilidad/' + contact.customer_id_dexga);
  }

  deployLegend(){
    if(this.allowDeployLegend){
      this.allowDeployLegend = false;
    }else{
      this.allowDeployLegend = true;
    }
  }
  
  openAgenda(){
    this.router.navigateByUrl('tienda/agendas');
  }
}
