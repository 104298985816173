import { Component, OnInit } from '@angular/core';
import { Storage } from "@ionic/storage";
import { Company } from 'src/app/models/admin/Company';
import { Constants } from 'src/app/services/constants';
import { AdminApiService } from 'src/app/services/admin/admin-api.service';
import * as pdfjsLib from 'pdfjs-dist'; 

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.page.html',
  styleUrls: ['./catalogue.page.scss'],
})
export class CataloguePage implements OnInit {

  public ready = false;
  public company: Company[];
  
  public cataloguesPDF : any;
  public allowCatalogue = false;
  public numberCataloguesPDF : any;
  public catalogueName = [];
  public catalogueId = [];

  constructor(
    private storage:Storage,
    private _peti: AdminApiService,
    private constants: Constants
  ) { }

  ngOnInit() {
    this.getCompany();
  }


  getCompany(){
    this.storage.get('company').then(res => {
      this.company = res;
      console.log(res)
      this.getNumberCatalogues(res);
    });
  }

  
  getNumberCatalogues(res){
      this.storage.get(this.constants.TOKEN_KEY).then(res1 => {
        this._peti.getNumberCatalogues(res1, res['company_id_dexga']).subscribe(result => {
          this.numberCataloguesPDF = Array(result).fill(0).map((x,i)=>'myCanvas'+(i+1));
          this.getCatalogue(res);
        }, erro => {
          if(erro.status == 401){
          }
        });
      });
  }

  getCatalogue(company){
      this.storage.get(this.constants.TOKEN_KEY).then(res1 => {
        this._peti.getCatalogues(res1, company['company_id_dexga']).subscribe(result => {
          this.cataloguesPDF = result;
          this.catalogueName = [];
          this.catalogueId = [];
          for (const catalogue of  Object.keys(this.cataloguesPDF)) {
            this.catalogueName.push(this.cataloguesPDF[catalogue]['name']);
            this.catalogueId.push(this.cataloguesPDF[catalogue]['catalogue_id'])
          }
          this.allowCatalogue = true;
          this.printPDF();
        }, erro => {
          if(erro.status == 401){
          }
        });
      });
  }

  
  async printPDF(){
    var index = 0;
    for (const catalogue of  Object.keys(this.cataloguesPDF)) {
      var catalogueTemp:any;
      catalogueTemp = this._base64ToArrayBuffer(this.cataloguesPDF[catalogue]['content']);
      pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
        const loadingTask = pdfjsLib.getDocument(catalogueTemp);
        await loadingTask.promise.then(function(pdf) {
          
          pdf.getPage(1).then(page => {
            var myCanvas = <HTMLCanvasElement>document.getElementById("myCanvas" + index);
            var context = myCanvas.getContext("2d");

            var viewport = page.getViewport(1);
            myCanvas.width = viewport.width;
            myCanvas.height = viewport.height;

            page.render({
              canvasContext: context,
              viewport: viewport
            });
          });
      });
      index++;

    }
  }

  _base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  download(event){
    var numero = event.target['id'].substring(8)
    console.log(this.cataloguesPDF[numero-1]);
  }

}
