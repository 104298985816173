import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {FullCalendarComponent} from "@fullcalendar/angular";
import {AppointmentApiService} from "../services/company/appointment-api.service";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import {filter, tap} from "rxjs/operators";
import esLocale from '@fullcalendar/core/locales/es';
import {LoadingController, ModalController, ToastController} from "@ionic/angular";
import {CustomerApiService} from "../services/customer/customer-api.service";
import * as moment from "moment";


@Component({
  selector: 'app-customer-calendar',
  templateUrl: './customer-calendar.component.html',
  styleUrls: ['./customer-calendar.component.scss'],
})
export class CustomerCalendarComponent implements AfterViewInit{
  @ViewChild('calendar') calendar:FullCalendarComponent;
  @Input('userId') userId;
  calendarApi;

  options;

  events: any[]=[];

  appointments:any;

  calendarView : string = "week";

  loader;

  constructor(
      private modalController:ModalController,
      private customerApiService:CustomerApiService,
      private toastController:ToastController,
      private loadingController : LoadingController
  ) {

  }

  ngOnInit() {


    /*Creamos las opciones del calendario */
    this.options = {
      // Tema
      theme: 'standart',
      // Lo que aparece arriba
      header: {
        // A la izquierda arriba
        left: 'prev',
        // El centro
        center: 'title',
        // La derecha para ver las diferentes vistas 'timeGridWeek,timeGridDay'
        right: 'next'
      },
      titleFormat: { // will produce something like "Tuesday, September 18, 2018"
        month: 'numeric',
        year: 'numeric',
        day: 'numeric'
      },views:{
            dayGridMonth:{
                eventLimit:4,
                titleFormat:{month:'long', year:'numeric'},
            },
            timeGridWeek:{
              columnHeaderFormat:{ weekday: 'short',day:'numeric', omitCommas: true }
            }
          },
      // Idioma español
      locale: esLocale,
      now : function(){return moment().add(moment().utcOffset(),"minutes").toISOString();},
      // Plugins que se necesitan
      plugins: [dayGridPlugin, timeGridPlugin,interactionPlugin,resourceTimeGridPlugin ]
    };

      let promise = this.loadingController.create({
        spinner: 'circles',
        message: 'Cargando citas',
        translucent: true,
        backdropDismiss: false
      });
      promise.then(loader =>{
        loader.present();
        this.loader = loader;
      });
      
    this.getAppointments(this.userId);
  }

  ngAfterViewInit() {
    this.calendarApi=this.calendar.getApi();
  }

  getAppointments(customerId){
    this.customerApiService.getCustomerActiveSchedule(customerId,false).pipe(filter((appointment:any) => !appointment.cancelation_date ),tap(appointments=>{
      let events=[];
      for (let [key, appointment] of Object.entries(appointments)){
        //console.log(appointment)
        let event=this.createEvent(appointment);
        events.push(event);
      }

      this.events=events;


    })).subscribe(appointments=>{
      
      this.appointments=appointments;
      this.loader.dismiss();
      //Si un usuario está seleccionado, pintamos las zonas ocupadas de rojo
    });
  }

  dateClick(event){
    if(event.allDay){ //No se ha seleccionado hora
      this.calendarApi.changeView('timeGridDay',event.date);
      this.calendarView = "day";
    }else{ //Se selecciona hora
      if(event.resource==undefined){ //No se selecciona agenda
        this.calendarApi.changeView('timeGridDay',event.date);
        this.calendarView = "day";
      }else{ //Se selecciona agenda

      }
    }
  }

  presentToast(msg: string, color: string) {
    this.toastController.create({
      color,
      message: msg,
      duration: 4000
    }).then(toast=>toast.present());
  }

  dayRender(ev) {
    /*if(ev.view.viewSpec.type=="timeGridDay")
      ev.el.style.backgroundColor="red";*/
  }


  eventClick(event: any) {

  }

  createEvent(appointment){
      return {
        'id':appointment.appointment_id_dexga,
        'editable':false,
        'title':appointment?.schedule?.company?.brand_name,
        'date':appointment.start,
        'start':appointment.start,
        'end':appointment.end,
        'appointment':appointment
      };
  }

  dayViewSelected(){
    this.calendarApi.changeView('timeGridDay');
    this.calendarView = "day";
  }

  weekViewSelected(){
    this.calendarApi.changeView('timeGridWeek');
    this.calendarView = "week";
  }

  monthViewSelected(){
    this.calendarApi.changeView('dayGridMonth');
    this.calendarView = "month";
  }

}
