import {Component, Input, OnInit} from '@angular/core';
import {AlertController, ModalController, ToastController} from "@ionic/angular";
import {CustomerApiService} from "../../services/customer/customer-api.service";
import * as moment from "moment";
import {AppointmentApiService} from "../../services/company/appointment-api.service";
import {Storage} from "@ionic/storage";
import {Constants} from "../../services/constants";

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent implements OnInit {

  loading : boolean = false;

  @Input('event') event;

  canNotify : boolean = false;
  canCancel : boolean = false;
  dateTitle;
  startShort;
  endShort;

  constructor(
      private modalController: ModalController,
      private alertController : AlertController,
      private customerApiService : CustomerApiService,
      private appointmentApiService: AppointmentApiService,
      private toast: ToastController,
      private storage: Storage,
      private constants: Constants
  ) { }

  ngOnInit() {
    let date=moment(this.event.start).toISOString();
    let now=moment().add(moment().utcOffset(),"minutes").toISOString();
    let today = moment().startOf("day").add(moment().utcOffset(),"minutes").toISOString();
    if(date>now) { //Si la hora aún no ha pasado
      this.canCancel = true;
    }
    if(date>=today){ //Si no es un día pasado puedes notificar citas de horas pasadas del mismo día (en caso de ir con retraso)
      this.canNotify = true;
    }
    
    var dia = new Date (this.event.start).toString().substring(8,10);
    var mes = new Date (this.event.start).toString().substring(4,7);
    var diaString = new Date (this.event.start).toString().substring(0,3);
    if (diaString == "Mon") { diaString = "LUN"}
    if (diaString == "Tue") { diaString = "MAR"}
    if (diaString == "Wed") { diaString = "MIE"}
    if (diaString == "Thu") { diaString = "JUE"}
    if (diaString == "Fri") { diaString = "VIE"}
    if (diaString == "Sat") { diaString = "SAB"}
    if (diaString == "Sun") { diaString = "DOM"}
    if (mes == "Jan") { mes = "enero"}
    else if (mes == "Feb") { mes = "febrero"}
    else if (mes == "Mar") { mes = "marzo"}
    else if (mes == "Apr") { mes = "abril"}
    else if (mes == "May") { mes = "mayo"}
    else if (mes == "Jun") { mes = "junio"}
    else if (mes == "Jul") { mes = "julio"}
    else if (mes == "Aug") { mes = "agosto"}
    else if (mes == "Sep") { mes = "septiembre"}
    else if (mes == "Oct") { mes = "octubre"}
    else if (mes == "Nov") { mes = "noviembre"}
    else if (mes == "Dec") { mes = "diciembre"}
    this.dateTitle = diaString + ' ' + dia + ' ' + mes;
    this.startShort = new Date (this.event.start).toString().substring(15,21);
    this.endShort = new Date (this.event.end).toString().substring(15,21);
  }

  dismiss(){
    this.modalController.dismiss();
  }

  notify(){
    const promise = this.alertController.create({
      header: 'Avisar cliente',
      message: '¿Seguro que quieres <strong>avisar al cliente</strong>?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'primary'
        }, {
          text: 'Avisar',
          cssClass : 'blue',
          handler: () => {
            this.notifyClient();
          }
        }
      ]
    });

    promise.then(alert=>{
      alert.present();
    });

  }

  cancel(){
    const promise = this.alertController.create({
      header: 'Anular cita',
      message: '¿Seguro que quieres <strong>anular la cita</strong>?',
      inputs: [
        {
          name: 'cancelation_reason',
          type: 'text',
          placeholder: 'Motivo (opcional)'
        }
      ],
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'primary'
        }, {
          text: 'Anular',
          cssClass : 'danger',
          handler: (data) => {
            this.abortAppointment(data.cancelation_reason);
          }
        }
      ]
    });

    promise.then(alert=>{
      alert.present();
    });
  }

  confirm(){
    const promise = this.alertController.create({
      header: 'Confirmar cita',
      message: '¿Seguro que quieres <strong>confirmar la cita</strong>?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'primary'
        }, {
          text: 'Confirmar',
          cssClass : 'success',
          handler: (data) => {
            this.confirmAppointment();
          }
        }
      ]
    });

    promise.then(alert=>{
      alert.present();
    });
  }


  abortAppointment(cancelation_reason:string){
    let data={
      cancel:true,
      cancelation_reason:cancelation_reason
    };
    this.loading = true;
    this.customerApiService.setCancelationDate(this.event.appointment_id_dexga,data ).subscribe(_=>{
      this.loading = false;
      this.modalController.dismiss({action:"cancel", motivo: cancelation_reason});
      
    }, error=>{
      this.modalController.dismiss();
    });

    this.storage.get(this.constants.USER_ID).then(res => {
      //Creamos la notificación
      let notification = {
          user_type: 'customer',
          user_id: res,
          user_id_send: this.event.customer.customer_id_dexga,
          title: 'Anulación de cita',
          seen: 0,
          description: 'Anulada la cita para el día ' + (this.event.start).substring(8,10) + ' del ' + (this.event.start).substring(5,7) + ' de ' + (this.event.start).substring(0, 4) +  ' desde las ' +
          (this.event.start).substring(11,16) + ' hasta las ' + (this.event.end).substring(11, 16) + '.',
          alias: this.event.customer.name,
          start: this.event.start,
          finish: this.event.end,
          phone: this.event.customer.phone
      }

      // this.appointmentApiService.createNotification(res, notification).subscribe(result => {
      // })
  });    


  }

  deleteAppointment(){
    const promise = this.alertController.create({
      header: 'Eliminar cita',
      message: '¿Seguro que quieres <strong>eliminar la cita</strong>? Se enviará una notificación al cliente.',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'primary'
        }, {
          text: 'Eliminar',
          cssClass : 'danger',
          handler: () => {
            this.appointmentApiService.deleteAppointment(this.event.appointment_id_dexga).subscribe();
            this.modalController.dismiss({action:"delete"});
          }
        }
      ]
    });

    promise.then(alert=>{
      alert.present();
    });
  }

  public notifyClient(){
    this.appointmentApiService.notify(this.event.appointment_id_dexga, this.event).subscribe(result => {
        
       this.presentToast("Se ha avisado correctamente.");

    }, error => {

         this.presentToast("Ha ocurrido un error al avisar", "danger");
    });
  }

  async presentToast(message, color = "success") {
    const toast = await this.toast.create({
        message,
        duration: 2000,
        color: color
    });
    toast.present();
  }

  confirmAppointment(){
    this.customerApiService.setConfirmationDate(this.event.appointment_id_dexga).subscribe(_=>{
      this.loading = false;
      this.presentToast("Se ha confirmado la cita.");
      this.modalController.dismiss();
    }, error=>{
      console.log(error);
      this.modalController.dismiss();
    });
  }

}

