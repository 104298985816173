import {PriorityTimeCreateModalComponent} from '../priority-time-create-modal/priority-time-create-modal.component';
import {PriorityTimeEditModalComponent} from '../priority-time-edit-modal/priority-time-edit-modal.component';
import { CustomerApiService } from 'src/app/services/customer/customer-api.service';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Constants } from 'src/app/services/constants';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';

@Component({
  selector: 'app-priority-time',
  templateUrl: './priority-time.component.html',
  styleUrls: ['./priority-time.component.scss'],
})
export class PriorityTimeComponent implements OnInit {

  public id;
  public priorities: any;
  public weekDay = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

  constructor(
      public modalController: ModalController, 
      private storage: Storage,
      private constants: Constants,
      private customerService: CustomerApiService,
      private toast: ToastController,
      private alertCtrl: AlertController,
      private auth: AuthService,
      @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit() {
    this.getCustomer();
    this.getPriorityTime();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  getCustomer() {
    this.storage.get(this.constants.CUSTOMER).then(res => {
        console.log(res);
        this.id = res;
    });
}

getPriorityTime() {
    this.storage.get(this.constants.CUSTOMER).then(res => {
       // console.log(res);
        this.customerService.getPriorityTime(res).subscribe(result => {
          console.log(result);
            this.priorities = result;
            for (const priorityTime of this.priorities) {
                priorityTime.week_day = this.weekDay[priorityTime.week_day - 1];
                priorityTime.start_hour = moment(priorityTime.start_hour, 'HH:mm:ss').format('HH:mm');
                priorityTime.finish_hour = moment(priorityTime.finish_hour, 'HH:mm:ss').format('HH:mm');
            }
            if (this.priorities.length === 0) {
                this.presentToast('No tiene ningún horario de preferencia');
            }
        }, err => {
         //   console.log(err);
            if (err.status === 404) {
                this.presentToast('Todavía no ha dado de alta ningún cliente.');
            } else {
                this.presentToast('Se ha producido un error. Inténtelo de nuevo más tarde.');
            }
            this.priorities = null;
        });
    });
}

deletePriorityTime(indice) {
    this.customerService.deletePriorityTime(this.priorities[indice].priority_time_id_dexga).subscribe(_ => {
        this.priorities.splice(indice, 1);
        this.presentToast('Horario de preferencia borrado con éxito', 'success');
    }, error => {
        this.presentToast('Ha ocurrido un error al borrar el horario de preferencia', 'danger');
    });
}

presentToast(message, color = 'success') {
    const promise = this.toast.create({
        message,
        duration: 2000,
        color,
    });
    promise.then(toast => toast.present());
    return promise;
}

editPriorityTime(indice) {
    const promise = this.modalController.create({
        component: PriorityTimeEditModalComponent,
        componentProps: {
            priorityTime: this.priorities[indice]
        }
    });
    promise.then(modal => {
        modal.present();
        modal.onDidDismiss().then(data => {
            if (data.data == null) {
                return;
            }

            const priorityTime = data.data.data;

            this.customerService.editPriorityTime(this.priorities[indice].priority_time_id_dexga, priorityTime).subscribe(priority => {
                const id = this.priorities[indice].priority_time_id_dexga;
                this.priorities[indice] = priorityTime;
                this.priorities[indice].priority_time_id_dexga = id;
                this.presentToast('Horario de preferencia actualizado con éxito');
            }, error => {
               // console.log(error);
                this.presentToast('Ha ocurrido un error al actualizar el horario de preferencia', 'danger');
            });
        });
    });
    return promise;
}

createPriorityTime() {
    return this.modalController.create({
        component: PriorityTimeCreateModalComponent
    }).then(modal => {
        modal.present();
        modal.onDidDismiss().then(data => {
            if (data.data == null) {
                return;
            }
            //console.log(data);
            this.customerService.savePriorityTime(this.id, data.data.data).subscribe((response: any) => {
                for (const priorityTime of response) {
                    priorityTime.week_day = this.weekDay[(priorityTime.week_day) - 1];
                    priorityTime.start_hour = moment(priorityTime.start_hour).format('HH:mm');
                    priorityTime.finish_hour = moment(priorityTime.finish_hour).format('HH:mm');
                    this.priorities.push(priorityTime);
                }
                this.presentToast('Horario de preferencia añadido con éxito', 'success');
            }, error => {
               // console.log(error);
                this.presentToast('Ha ocurrido un error al añadir el horario de preferencia', 'danger');
            });
        });
    });
}

deletePriorityTimeConfirm(indice) {
    this.alertCtrl.create({
        cssClass: '',
        header: 'Borrar horario de preferencia',
        message: '¿Está seguro de borrar este <strong>horario de preferencia</strong>?',
        buttons: [
            {
                text: 'Cancelar',
                role: 'cancel',
                cssClass: 'secondary',
                handler: (blah) => {
                  //  console.log('Confirm Cancel: blah');
                }
            }, {
                text: 'Borrar',
                handler: () => {
                    this.deletePriorityTime(indice);
                }
            }
        ]
    }).then(alert => alert.present());
}

}
