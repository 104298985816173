import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Constants} from '../constants';
import {Storage} from "@ionic/storage";
import {Appointment} from "../../models/company/appointment";
import {Customer} from "../../models/company/customer";
import {PriorityTime} from '../../models/customer/PriorityTime';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CustomerApiService {

    public url: string;
    public var: string;
    private token: any;
    private status: number;
    private id;

    constructor(
        private constants: Constants,
        public _http: HttpClient,
        private storage: Storage
    ) {
        this.url = "http://localhost:8000/";
    }

    getMyId() {
        this.storage.get(this.constants.CUSTOMER).then(val => {
            this.id = val;
        });
        return this.id;
    }

    /* conseguirToken(){
         return this.storage.get(this.constants.TOKEN_KEY).then(res => {
             this.setToken(res);
             console.log(this.token);
         });
     }*/

    getPriorityTime(id) {
        return this._http.get(this.constants.API_ROUTE + 'customers/' + id + '/priorityTime');
    }

    getCustomerAppointments(id) {
        return this._http.get<Appointment[]>(this.constants.API_ROUTE + "customers/" + id + '/appointments');
    }

    getCustomerSchedule(id) {
        return this._http.get(this.constants.API_ROUTE + 'customers/' + id + '/appointments');
    }

    getCustomerActiveSchedule(id, onlyFuture:any = true){
        let params = new HttpParams().set('canceled', 'false').set('onlyFuture',onlyFuture);
        return this._http.get(this.constants.API_ROUTE + 'customers/' + id + '/appointments', {params:params});
    }

    editCustomer(token, id, data) {
        return this._http.patch(this.constants.API_ROUTE + 'customers/' + id, data, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    }

    getOrder(customerId) {
        return this._http.get(this.constants.API_ROUTE + 'order/' + customerId);
    }

    //Priority time edit
    editPriorityTime(id,data) {
        return this._http.patch(this.constants.API_ROUTE + 'priorityTime/' + id, data);
    }

    savePriorityTime(customerId,data){
        return this._http.post(`${this.constants.API_ROUTE}customers/${customerId}/priorityTime`,data);
    }

    deletePriorityTime(priorityTimeId){
        return this._http.delete(`${this.constants.API_ROUTE}priorityTime/${priorityTimeId}`);
    }

    setConfirmationDate(id) {
        var data = {
            "confirm" : "true"
        }
        return this._http.patch(this.constants.API_ROUTE + 'appointments/' + id, data);
    }

    createCustomer(customer) {
        const body = {
            name: customer.name,
            first_surname: customer.first_surname,
            second_surname: customer.second_surname,
            email: customer.email,
            phone: customer.phone,
            username: customer.phone,
            password: customer.password,
            password_confirmation: customer.password
        };
        return this._http.post(this.constants.API_ROUTE + 'register', body, {
            headers: {
                'DONQ-Alive': '%j4B5%,EWzq]J2phhN8Nt.gJU3nPd]kd:n_+kUuQkBQtmYURaH3nPum=BL2$9dTZ'
            },
            observe: 'response'
        });
    }

    validateEmailCode(code, username) {
        return this._http.post(this.constants.API_ROUTE + 'users/' + username + '/verification', {
            code,
        }, {observe: 'response'});
    }

    getUserId(username) {

    }

    //Appointment cancelation
    setCancelationDate(id, data) {
        return this._http.patch(this.constants.API_ROUTE + 'appointments/' + id, data);
    }

    setPriorityTimeCancel(token, id, data) {
        return this._http.patch(this.constants.API_ROUTE + 'priorityTimeEdit/' + id, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    setAlert(id, data){
        return this._http.post(this.constants.API_ROUTE + 'users/' + id + '/user-alert', data);
    }

    setOptimizeTime(id, data){
        return this._http.post(this.constants.API_ROUTE + 'users/' + id + '/user-optimize-time', data);
    }

    //Set geolocation
    setGeolocation(token, id, data){
        return this._http.post(this.constants.API_ROUTE + 'users/' + id + "/locations", data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    getCustomer(token, data) {
        return this._http.get<Customer[]>(this.constants.API_ROUTE + "customers/" + data.customer_id);
    }

    getCustomerData(data) {
        return this._http.get<Customer[]>(this.constants.API_ROUTE + "customers/" + data);
    }

    getAllCompanyFilteredName(value): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'companies?brand_name=' + value);
    }

    getAllCompanyFilteredPhone(value): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'companies?phone=' + value);
    }

    getNameSchedules(id): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'companies/' + id + '/schedules');
    }

    getAppointments(companyId, scheduleId){
        return this._http.get(this.constants.API_ROUTE + 'companies/' + companyId + '/schedules/' + scheduleId + '/appointments');
    }

    getAllCompany(): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'companies');
    }

    getKeywords(token, customerId){
        return this._http.get(this.constants.API_ROUTE + 'keywords/' + customerId, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });   
    }

    getByKeywords(token, data){
        return this._http.post(this.constants.API_ROUTE + 'getByKeywords', data ,{
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });   
    }

    getCompanyLocation(token, companyId){
        return this._http.get(this.constants.API_ROUTE + 'company/location/' + companyId, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    setOrder(token, data){
        return this._http.post(this.constants.API_ROUTE + 'order', data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    createNotification(userId, notification){
        return this._http.post(`${this.constants.API_ROUTE}users/${userId}/notifications`, notification);
    }

    getAddress(customerId){
        return this._http.get(this.constants.API_ROUTE + 'address/' + customerId );
    }

    setAddress(data){
        return this._http.post(this.constants.API_ROUTE + 'address', data);
    }

    deleteAddress(addressId){
        return this._http.delete(`${this.constants.API_ROUTE}address/${addressId}`);
    }

    deleteAllAddress(customerId){
        return this._http.delete(`${this.constants.API_ROUTE}allAddress/${customerId}`);
    }

    getAllFlash(): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'flash');
    }

    cancelOrder(id, data){
        return this._http.patch(this.constants.API_ROUTE + 'order/' + id, data);
    }

    editNotification(id, data){
        return this._http.patch(this.constants.API_ROUTE + 'notifications/' + id, data);
    }

    editOrder(id, data){
        return this._http.patch(this.constants.API_ROUTE + 'order/' + id, data);
    }
}
