import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {map, take} from 'rxjs/operators';
import {MenuController} from "@ionic/angular";


@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    constructor(
        private auth: AuthService,
        private router: Router,
        private menuCtrl:MenuController
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot) {
        const expectedRole = route.data.expectedRole;

        return this.auth.getRole().then(role=> {

            if (expectedRole == role) {
                return true;
            } else {
                return this.router.parseUrl('/login');
                this.menuCtrl.enable(false);
            }

        });
    }

}
