import {Injectable} from '@angular/core';
import {Constants} from '../constants';
import {HttpClient} from '@angular/common/http';
import {Appointment} from '../../models/company/appointment';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AppointmentApiService {

    constructor(
        private constants: Constants,
        public http: HttpClient
    ) {
    }

    getMyAppointments(companyId) {
        return this.http.get<Appointment[]>(this.constants.API_ROUTE + "companies/" + companyId + "/appointments");
    }

    createAppointment(companyId,scheduleId,appointment):Observable<any>{
        return this.http.post(`${this.constants.API_ROUTE}companies/${companyId}/schedules/${scheduleId}/appointments`,appointment);
    }

    editAppointment(companyId,scheduleId,appointmentId,appointment){
        return this.http.patch(`${this.constants.API_ROUTE}companies/${companyId}/schedules/${scheduleId}/appointments/${appointmentId}`,appointment);
    }

    deleteAppointment(appointmentId){
        return this.http.delete(`${this.constants.API_ROUTE}appointments/${appointmentId}`);
    }

    checkLocation(data){
        return this.http.post(`${this.constants.API_ROUTE}distance`, data);
    }

    confirmAppointment(appointmentId, appointment){
        return this.http.post(`${this.constants.API_ROUTE}appointments/${appointmentId}`, appointment);
    }

    createNotification(userId, notification){
        return this.http.post(`${this.constants.API_ROUTE}users/${userId}/notifications`, notification);
    }

    notify(appointmentId, appointment){
        return this.http.post(`${this.constants.API_ROUTE}appointments/${appointmentId}/notify`, appointment);
    }

}
