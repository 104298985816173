import {Injectable} from '@angular/core';
import {Constants} from '../constants';
import {HttpClient} from '@angular/common/http';
import {Schedule} from '../../models/company/schedule';
import {Storage} from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class ScheduleApiService {

    constructor(
        private constants: Constants,
        public http: HttpClient
    ) {
    }

    getMySchedules(companyId) {
        return this.http.get<Schedule[]>(this.constants.API_ROUTE + 'companies/' + companyId + '/schedules');
    }

    updateSchedule(request) {
        return this.http.patch(this.constants.API_ROUTE + 'companies/' + request.companyId + '/schedules/' + request.id, request);
    }

    insertSchedule(request) {
        return this.http.post(this.constants.API_ROUTE + 'companies/' + request.company_id + '/schedules', request);
    }
}
