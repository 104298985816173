import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";

@Component({
    selector: 'app-warning-modal',
    templateUrl: './warning-modal.component.html',
    styleUrls: ['./warning-modal.component.scss'],
})
export class WarningModalComponent implements OnInit {

    @Input() title;
    @Input() message;

    constructor(private modalController: ModalController) {
    }

    ngOnInit() {
    }

    dismiss() {
        this.modalController.dismiss({"dismiss":true})
    }

    accept(){
        this.modalController.dismiss({"dismiss":false})
    }
}
