import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {Constants} from "../../../../services/constants";
import {Storage} from "@ionic/storage";
import {CustomerApiService} from '../../../../services/customer/customer-api.service';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-order-modal',
  templateUrl: './order-modal.component.html',
  styleUrls: ['./order-modal.component.scss'],
})
export class OrderModalComponent implements OnInit {
  
  status1: any;
  pageData : any;
  company;
  address;
  pedidoMostrar1: any;
  dondeEnviarMostrar = "Entrega a domicilio";
  paraCuandoMostrar = "Lo antes posible";
  addressMostrar;
  public keywords;
  public ready;
  public form;

  constructor(
    public modalController: ModalController,
    private constants:Constants,
    private storage:Storage,
    private _peti: CustomerApiService,
    public navParams: NavParams,
    private modalCtrl: ModalController
  ) { 
    this.pageData = {'username': "David" };
  }

  ngOnInit() {
    this.getCompany();
    this.getAddress();
    
    this.form = new FormGroup({
      pedido: new FormControl(''),
      direccion: new FormControl(''),
      pedidoMostrar: new FormControl(''),
      comercioMostrar: new FormControl(''),
      tiempoMostrar: new FormControl(''),
      dondeMostrar: new FormControl(''),
      address: new FormControl(''),
      gastoEnvioMostrar: new FormControl('')
    });

  }

  closeModal() {
    this.modalController.dismiss();
  }

  getCompany(){
    this.storage.get('company').then(res => {
      this.company = res;
      this.form.controls.gastoEnvioMostrar.setValue(res['shipping_costs']);
    });
  }

  getKeyword(){
    console.log("llega");
    var company_id = this.company.company_id_dexga;
    this.storage.get(this.constants.COMPANY).then(res => {
      this._peti.getKeywords(res, company_id).subscribe(result => {
        this.keywords = result;
        console.log(result);
        console.log(result[0]['name']);
        this.ready = true;
      });
    });
  }

  getAddress() {
    this.storage.get(this.constants.CUSTOMER).then(res => {
      this._peti.getAddress(res).subscribe(result => {
        this.address = result;
        this.form.controls.direccion.setValue(result[0]['street'] + "," + result[0]['number'] + "," + result[0]['stair'] + "," + result[0]['city']);
        this.addressMostrar = this.form.value.direccion;
        this.ready = true;
      })
    });
  }

  changeClick(event){
    this.status1 = !this.status1;
    this.dondeEnviarMostrar = event.currentTarget.innerText;
  }

  cambioPedido(){
    console.log('Llega');
    console.log(this.form.value.pedido);
    this.pedidoMostrar1 = this.form.value.pedido;
    this.form.controls.pedidoMostrar.setValue(this.form.value.pedido);
    console.log(this.form.value.pedido);
  }

  timeChange(event){
    this.paraCuandoMostrar = event.target.value;
    console.log('llega');
    console.log(event.target);
    console.log(event.target.value);
  }

  changeAddress(event){
    this.addressMostrar = event.target.value;
  }

  saveOrder(){
    var data = {
      company_id: this.company.company_id_dexga,
      order: this.form.value.pedido,
      date: this.paraCuandoMostrar,
      name:  this.company['brand_name']
    }
    console.log(this.dondeEnviarMostrar)
    if (this.dondeEnviarMostrar == "Entrega a domicilio") {
      data['type'] = "delivery";
      data['address'] = this.addressMostrar;
      data['shipping_costs'] = this.form.value.gastoEnvioMostrar;
    }else{
      data['type'] = "take_away";
    }

    var orderId;

    this.storage.get(this.constants.TOKEN_KEY).then(res => {
      this._peti.setOrder(res, data).subscribe(result => {
          console.log(result);
          orderId = result['order_id'];
          
          this.storage.get(this.constants.USER_ID).then(res => {
            
            this._peti.getCustomerData(result['customer_id']).subscribe(customer => {
            //Creamos la notificación
              let notification = {
                  order_id: orderId,
                  user_type: 'company',
                  user_id: res,
                  user_id_send: this.company.company_id_dexga,
                  name: this.company.brand_name,
                  phone_company: this.company.phone,
                  title: 'Petición de pedido',
                  seen: 0,
                  description: 'Pedido realizado para ' + this.paraCuandoMostrar + '. ' + this.dondeEnviarMostrar + ' un pedido de ' + this.pedidoMostrar1,
                  alias: customer[0]['name'],
                  phone: customer[0]['phone']
              }
              this._peti.createNotification(res, notification).subscribe(result => {
              }, error => {
              })
            });
          });
          this.closeModal();
      }, erro => {
          console.log(erro);
          this.closeModal();
      });
    });

  }

}
