import { Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {Storage} from '@ionic/storage';
import {Constants} from '../../../../services/constants';
import {CustomerApiService} from '../../../../services/customer/customer-api.service';
import {AlertController, ModalController, PickerController, ToastController} from '@ionic/angular';
import {Company} from 'src/app/models/admin/Company';
import {Schedule} from 'src/app/models/admin/Schedule';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import * as moment from 'moment';
import { tap, filter } from 'rxjs/operators';
import {AppointmentApiService} from "../../../../services/company/appointment-api.service";
import { FullCalendarComponent } from '@fullcalendar/angular';
import { diffMinutes } from '@fullcalendar/core/datelib/marker';
import { CompanyDashboardPageModule } from 'src/app/pages/company/company-dashboard/company-dashboard.module';

@Component({
  selector: 'app-appointment-create-modal',
  templateUrl: './appointment-create-modal.component.html',
  styleUrls: ['./appointment-create-modal.component.scss'],
})
export class AppointmentCreateModalComponent implements OnInit{
    
    @ViewChild('calendar') calendar:FullCalendarComponent;
    public allowSearch = true;
    public allowSchedule = false;
    public allowSummary = false;
    public allowTime = false;
    public search = false;
    public allowCalendar = false;
    public data;
    public companies: Company[] = [];
    public companySave: Company[] = [];
    public schedules: Schedule[] = [];
    public scheduleId;
    public customerId;
    public date;
    public hour;
    public scheduleName;
    public defaultDate;
    public minHour;
    public maxHour;
    createForm: FormGroup;
    myGroup: FormGroup;
    filterResultDataSet = [];
    minDate: string = new Date().toISOString();
    maxDate:string = (new Date(new Date().setFullYear(new Date().getFullYear() + 1))).toISOString();
    yearValues = [new Date().getFullYear(), new Date().getFullYear() + 1]
    events: any[]=[];
    appointments:any;
    options;
    calendarApi;

    public allowSelect;
    public arrDateTemp = [];
    public placeholder = "Selecciona una hora";

    constructor(
        private storage: Storage,
        private constants: Constants,
        public modalController: ModalController,
        private _peti: CustomerApiService,
        private fb: FormBuilder,
        private toastController:ToastController,
        public alertController: AlertController,
        private appointmentApiService: AppointmentApiService,
        public pickerController: PickerController
    ) {
        this.createForm = this.fb.group({
            week_days: [[], Validators.required],
            start_hour: ['', Validators.required],
            finish_hour: ['', Validators.required],
            blocked: [false]
        });

        this.myGroup = new FormGroup({
            scheduleName: new FormControl(),
            initDate: new FormControl(),
            message: new FormControl()
         });

    }

    ngOnInit(){
        this.storage.get(this.constants.CUSTOMER).then(res => {
            this.customerId = res;
        });
    }

    clearResults() {
        
    }

    closeModal() {
        this.modalController.dismiss();
    }

    filterResult($event) {
        this.data = $event.target.value;
        console.log(this.data);
        if(this.data.length < 2){
            //TODO: mensaje para que el usuario sepa que más de dos caracteres para iniciar búsqueda
            this.companies = null;
        }else{
            this.search = true;
            if(isNaN(this.data)){
                this._peti.getAllCompanyFilteredName(this.data).subscribe(result => {
                    let resources=[];
                    for(let [key, schedule] of Object.entries(result)){
                        //console.log(schedule)
                        if(schedule['leaving_date']==null){
                        resources.push(schedule);
                        }
                    }
                    this.companies = resources;
                }, err => {
                    this.companies = null;
                });
            }else{
                this._peti.getAllCompanyFilteredPhone(this.data).subscribe(result => {
                    let resources=[];
                    for(let [key, schedule] of Object.entries(result)){
                        console.log(schedule)
                        if(schedule['leaving_date']==null){
                        resources.push(schedule);
                        }
                    }
                    this.companies = resources;
                }, err => {
                    this.companies = null;
                });
            }
        }
      }

    saveCompany(company){
        this.companySave = company;
        if (this.companySave['open_hour_1'] == null || this.companySave['close_hour_1'] == null) {
            this.minHour = '08:00';
            this.maxHour = '21:00';
        }else {
            this.minHour = this.companySave['open_hour_1'].substring(11,16);
            if(this.companySave['close_hour_2'] != null){
                this.maxHour = this.companySave['close_hour_2'].substring(11,16);
            }else{
                this.maxHour = this.companySave['close_hour_1'].substring(11,16);
            }
        }
        this.allowSearch = false;
        this.search = false;
        this.allowSelect = true;
        this.getSchedules(company['company_id_dexga']);
    }  

    getSchedules(id){
        this._peti.getNameSchedules(id).subscribe(result => {
            this.schedules = result;
        }, err => {
        });
    }

    saveScheduleId(){
        this.scheduleId = this.myGroup.value.scheduleName;
        this.allowSchedule = false;
        this.allowTime = true;
    }

    presentToast(msg: string, color: string = "success") {
        this.toastController.create({
          color,
          message: msg,
          duration: 4000
        }).then(toast=>toast.present());
    }

    select(event){
        var dia = String(this.arrDateTemp[event['col-0']['value']]['value']);
        var start = dia.substring(0,16) + event['col-1']['text'].substring(0,5) + dia.substring(21);
        var startCompare = dia.substring(0,16) + (parseInt(event['col-1']['text'].substring(0,2)) + parseInt(dia.substring(30,31)) ) + ":" + event['col-1']['text'].substring(3,5) + dia.substring(21);
        var end = dia.substring(0,16) + event['col-1']['text'].substring(8) + dia.substring(21);
        this.date = {start: start, end: end,};
        console.log(startCompare);
        console.log(start);
        console.log(dia);
        console.log(this.arrDateTemp);
        console.log(event['col-0']['value']);
        console.log(this.arrDateTemp[event['col-0']['value']]);
        console.log(this.arrDateTemp[event['col-0']['value']]['value']);
        let date = moment(new Date(startCompare)).toISOString();
        let now = moment().add(moment().utcOffset(),"minutes").toISOString();
        console.log(date);console.log(now);
        if(date<now) { //Si la fecha ya ha pasado
            this.presentToast("No se pueden añadir citas en fechas pasadas.","danger");
            return;
        } if (date.substring(11,19) >= this.companySave['close_hour_1'].substring(11) &&
              date.substring(11,19) < this.companySave['open_hour_2'].substring(11)) {
            this.presentToast("La tienda está cerrada a esta hora.","danger");
            return;
        }
        
        this.placeholder = event['col-0']['text'] + '          ' + event['col-1']['text'];

    }

    createAppointment() {
        if (this.scheduleId != null && this.scheduleId != '' && this.date.end != null && this.date.end != '' && this.date.start != null && this.date.start != '') {
            
            
            let appointment = {
                schedule_id: this.scheduleId,
                customer_id: this.customerId,
                start: this.date.start.substring(0, 25),
                end: this.date.end.substring(0, 25),
                need_confirmation: true,
                comment: this.myGroup.value.message
            }
            console.log(this.date.start.substring(0, 25));
            console.log(appointment);

            this.appointmentApiService.createAppointment(this.companySave['company_id_dexga'], this.scheduleId, appointment).subscribe(result => {
                this.presentToast("Cita creada con éxito", "primary");
                // result['alias']= this.client.alias;
                this.modalController.dismiss({"appointment": result});
                console.log(result);
            }, error => {
                console.log(error)
                if (error.status == 400) {
                    this.presentToast("El cliente ya tiene una cita para esas horas", "danger");
                } else {
                    this.presentToast("Ha ocurrido un error", "danger");
                }

            });

            var mes = (this.date.start).substring(4, 7);
            if (mes == "Jan") { mes = "Enero"}
            else if (mes == "Feb") { mes = "Febrero"}
            else if (mes == "Mar") { mes = "Marzo"}
            else if (mes == "Apr") { mes = "Abril"}
            else if (mes == "May") { mes = "Mayo"}
            else if (mes == "Jun") { mes = "Junio"}
            else if (mes == "Jul") { mes = "Julio"}
            else if (mes == "Aug") { mes = "Agosto"}
            else if (mes == "Sep") { mes = "Septiembre"}
            else if (mes == "Oct") { mes = "Octubre"}
            else if (mes == "Nov") { mes = "Noviembre"}
            else if (mes == "Dec") { mes = "Diciembre"}

            this._peti.getCustomerData(this.customerId).subscribe(customer => {
                this.storage.get(this.constants.USER_ID).then(res => {
                    //Creamos la notificación
                    let notification = {
                        user_type: 'company',
                        user_id: res,
                        user_id_send: this.companySave['company_id_dexga'],
                        title: 'Petición de cita',
                        seen: 0,
                        description: 'Cita para el día ' + (this.date.start).substring(8, 10) +' de ' + mes +  ' a las ' +
                        (this.date.start).substring(16, 21),
                        alias: customer[0]['name'],
                        start: new Date(this.date.start),
                        finish: new Date(this.date.end),
                        phone: customer[0]['phone']
                    }
        
                    this.appointmentApiService.createNotification(res, notification).subscribe(result => {
                        console.log(result);
                    }, error => {
                        console.log(error);
                    })
                });    
            }, error => {

                console.log(error);
            });
        } else {
            this.presentToast("Se debe elegir con quién se quiere cita y la fecha.","danger");
        }
        
    }

    async openPicker(numColumns = 1, numOptions = 5){

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        var arrDiaSemana = [];
        var arrHoras = [];

        for (let index = 0; index < 31; index++) {

            var day = mm + '/' + dd + '/' + yyyy;
            var fecha = this.addDays(day, index);
            var diaSemana = fecha.toString().substring(0,3);
            console.log(diaSemana);
            var numeroMes = fecha.toString().substring(8,10);
            if (diaSemana == "Mon") { diaSemana = "Lunes " + numeroMes }
            if (diaSemana == "Tue") { diaSemana = "Martes " + numeroMes }
            if (diaSemana == "Wed") { diaSemana = "Miércoles " + numeroMes }
            if (diaSemana == "Thu") { diaSemana = "Jueves " + numeroMes }
            if (diaSemana == "Fri") { diaSemana = "Viernes " + numeroMes }
            if (diaSemana == "Sat") { diaSemana = "Sábado " + numeroMes }
            if (diaSemana == "Sun") { diaSemana = "Domingo " + numeroMes }
            if (index == 0) { diaSemana = "Hoy"} 
            if (index == 1) { diaSemana = "Mañana"} 
            arrDiaSemana.push(diaSemana);

            var diaSemana = fecha.toString().substring(0,3);
            var dataTemp = {};
            if (diaSemana == "Mon") { dataTemp = {value : fecha, text : "Lunes " + numeroMes} }
            if (diaSemana == "Tue") { dataTemp = {value : fecha, text : "Martes " + numeroMes} }
            if (diaSemana == "Wed") { dataTemp = {value : fecha, text : "Miércoles " + numeroMes} }
            if (diaSemana == "Thu") { dataTemp = {value : fecha, text : "Jueves " + numeroMes} }
            if (diaSemana == "Fri") { dataTemp = {value : fecha, text : "Viernes " + numeroMes} }
            if (diaSemana == "Sat") { dataTemp = {value : fecha, text : "Sábado " + numeroMes} }
            if (diaSemana == "Sun") { dataTemp = {value : fecha, text : "Domingo " + numeroMes} }
            if (index == 0) { dataTemp = {value : fecha, text : "Hoy"} } 
            if (index == 1) { dataTemp = {value : fecha, text : "Mañana"} } 
            this.arrDateTemp.push(dataTemp);
        }

            console.log(this.arrDateTemp);
        var longitudCita = this.companySave['appointment_default_length'];

        //Parte de creación del array de las horas
        if (this.companySave['open_hour_1'] != null && this.companySave['close_hour_1'] != null) {
            var horaInicial1 = new Date(this.companySave['open_hour_1']);
            var horaFinal1 = new Date(this.companySave['close_hour_1']);
            var index=1;

            do {
                var hora;
                if (index == 1) {
                   hora =  horaInicial1.toString().substring(16, 21);
                } else {
                    hora =  arrHoras[index-2].toString().substring(8);
                }

                arrHoras.push(hora + " - " + this.addMinutes(horaInicial1, (longitudCita * index) ).toString().substring(16, 21));
                index++;
                
            } while (this.addMinutes(horaInicial1, (longitudCita * index) ).toString().substring(16, 21) <= horaFinal1.toString().substring(16, 21));
           
        }
        if (this.companySave['open_hour_2'] != null && this.companySave['close_hour_2'] != null) {
            var arrTemp = [];
            var horaInicial2 = new Date (this.companySave['open_hour_2']);
            var horaFinal2 = new Date (this.companySave['close_hour_2']);
            var index = 1;
            
            do {
                var hora;
                if (index == 1) {
                   hora =  horaInicial2.toString().substring(16, 21);
                } else {
                    hora =  arrTemp[index-2].toString().substring(8);
                }

                arrHoras.push(hora + " - " + this.addMinutes(horaInicial2, (longitudCita * index) ).toString().substring(16, 21));
                arrTemp.push(hora + " - " + this.addMinutes(horaInicial2, (longitudCita * index) ).toString().substring(16, 21));
                index++;
                
            } while (this.addMinutes(horaInicial2, (longitudCita * index) ).toString().substring(16, 21) <= horaFinal2.toString().substring(16, 21));
            
        }

        var multiColumnOptions = [];
        multiColumnOptions.push(arrDiaSemana);
        multiColumnOptions.push(arrHoras);   

        const picker = await this.pickerController.create({
          columns: this.getColumns(numColumns, numOptions, multiColumnOptions),
          buttons: [
            {
              text: 'Cancelar',
              role: 'cancel'
            },
            {
              text: 'Confirmar',
              handler: (value) => {
                this.select(value);
              }
            }
          ]
        });
  
        await picker.present();
      }

    addDays(date, days) {
        const copy = new Date(date)
        copy.setDate(copy.getDate() + days)
        return copy;
    }

    addMinutes(date, minutes) {
        return new Date(date.getTime() + minutes*60000);
    }
  
    getColumns(numColumns, numOptions, columnOptions) {
        let columns = [];
        for (let i = 0; i < numColumns; i++) {
          columns.push({
            name: `col-${i}`,
            options: this.getColumnOptions(i, numOptions, columnOptions)
          });
        }
  
        return columns;
      }
  
    getColumnOptions(columnIndex, numOptions, columnOptions) {
        let options = [];
        for (let i = 0; i < numOptions; i++) {
          options.push({
            text: columnOptions[columnIndex][i % numOptions],
            value: i
          })
        }
  
        return options;
      }
}
