import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {NavParams} from '@ionic/angular';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent implements OnInit {
  @Input('section') public section = 'seccion original';

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit() {}

  chargeNoti(){
    this.router.navigateByUrl("cliente/notificaciones");
  }

}
