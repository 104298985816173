import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
//import {HeaderComponent} from 'src/app/components/header/header.component';
import {IonicModule} from '@ionic/angular';


@NgModule({
    declarations: [
        //HeaderComponent
    ],
    exports: [
        //HeaderComponent
    ],
    imports: [
        CommonModule,
        IonicModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class HeaderModule {

}
