import {Injectable, NgZone} from '@angular/core';
import {Storage} from '@ionic/storage';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {tap} from 'rxjs/operators';
import {Constants} from '../services/constants';
import {CompanyApiService} from './company/company-api.service';
import {CustomerApiService} from './customer/customer-api.service';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {MenuController} from '@ionic/angular';

const helper = new JwtHelperService();

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public user: any;
    public versions: any;

    constructor(
        private storage: Storage,
        private http: HttpClient,
        private router: Router,
        private constants: Constants,
        private companyApiService: CompanyApiService,
        private customerApiService: CustomerApiService,
        private appVersion: AppVersion,
        private ngZone: NgZone,
        public menuCtrl: MenuController,
    ){
        this.loadUser();
    }

    login(credentials){
        let user = null;

        return this.http.post(this.constants.API_ROUTE+"login", credentials).pipe(tap(response => {
            const helper = new JwtHelperService();

            const decodedToken = helper.decodeToken(response['jwt']['token_session']);
            const user_id = decodedToken['data']['id'];
            const user_id_dexga = decodedToken['data']['user_id_dexga'];
            const role = decodedToken['data']['role'];

            this.storage.set(this.constants.USER_ROLE, role);
            user = {role: role};

            if(role == 'company'){
                const company_id = decodedToken['data']['company_id'];
                this.storage.set(this.constants.COMPANY, company_id);
            }

            if(role == "customer"){
                const customer_id = decodedToken['data']['customer_id'];
                this.storage.set(this.constants.CUSTOMER, customer_id);
            }

            this.storage.set(this.constants.TOKEN_KEY, response['jwt'].token_session);
            this.storage.set(this.constants.USER_ID, user_id_dexga);
            this.loadUser();
        },
        err => {
            console.log(err);
            console.log('bad login');
        })
        );
    }

    loadUser(){
        this.storage.get(this.constants.USER_ROLE).then(data => {
            if (data) {
                switch (data) {
                    case 'customer':
                        this.getMyCustomerId();
                        break;
                    case 'company':
                        this.getMyCompanyId();
                        break;
                    case 'admin':
                        break;
                    default:
                        break;
                }

            } else {
            }

        return data;
        });
    }

    logout(){
        this.http.patch(this.constants.API_ROUTE + 'logout', '').subscribe(result => {});
        this.storage.remove(this.constants.TOKEN_KEY).then(() => {
        this.storage.remove(this.constants.USER_ROLE).then(() => {
            this.storage.remove(this.constants.COMPANY).then(() => {
                this.storage.remove(this.constants.CUSTOMER).then(() => {
                    this.menuCtrl.enable(false);
                    this.router.navigateByUrl('/login');
                });
            });
        });
        });
    }

    getMyCompanyId(){
        this.storage.get(this.constants.TOKEN_KEY).then(token =>{
            this.companyApiService.getMyId(token).subscribe(result => {
                console.log(result);
                // this.storage.set(this.constants.COMPANY, result['company_id']);
            }, error => {
                //console.log(error);
            });
        });
    }

    getMyCustomerId(){
        this.storage.get(this.constants.TOKEN_KEY).then(token =>{
            this.customerApiService.getMyId().subscribe(result => {
                this.storage.set(this.constants.CUSTOMER, result['customer_id']);
            }, error => {
                console.log(error);
            });
        });
    }

    sendCustomerLoginCode(credentials){
        return this.http.post(this.constants.API_ROUTE+"customer-login", credentials);
    }

    customerLogin(credentials){
        let user=null;
        return this.http.post(this.constants.API_ROUTE+"customer-login/code", credentials).pipe(tap(response=>{
            const helper = new JwtHelperService();

            const decodedToken = helper.decodeToken(response['jwt']['token_session']);
            const user_id = decodedToken['data']['id'];
            const user_id_dexga = decodedToken['data']['user_id_dexga'];
            const role = decodedToken['data']['role'];

            this.storage.set(this.constants.USER_ROLE, role);
            user = {role: role};

            if(role == "customer"){
                const customer_id = decodedToken['data']['customer_id'];
                console.log(customer_id);
                this.storage.set(this.constants.CUSTOMER, customer_id);
            }

            this.storage.set(this.constants.TOKEN_KEY, response['jwt'].token_session);
            this.storage.set(this.constants.USER_ID, user_id_dexga);
            this.loadUser();
        }));
    }

    ///////////
    getRole(){
        return this.storage.get(this.constants.USER_ROLE);
    }

    getMyCompanyIdWell(){
        return this.storage.get(this.constants.COMPANY);
    }

    getMyCustomerIdWell(){
        return this.storage.get(this.constants.CUSTOMER);
    }

    getVersion(){
        return new Promise((resolve, reject) => {
            this.http.get(this.constants.API_ROUTE + 'version')
                .subscribe(
                    data => {
                        resolve(data)
                    },
                    error => {
                        reject(error);
                    },
                );
        });
    }

    getAppVersion(){
        return this.appVersion.getVersionNumber().then(
            (versionNumber) => {
                return versionNumber;
            },
            (error) => {
                //console.log(error);
            });
    }
}
