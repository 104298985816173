import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Storage} from '@ionic/storage';
import {Constants} from '../../../../services/constants';
import {CustomerApiService} from '../../../../services/customer/customer-api.service';
import {ModalController} from '@ionic/angular';

@Component({
    selector: 'app-priority-time-create-modal',
    templateUrl: './priority-time-create-modal.component.html',
    styleUrls: ['./priority-time-create-modal.component.scss'],
})
export class PriorityTimeCreateModalComponent {

    createForm: FormGroup;

    constructor(
        private storage: Storage,
        private constants: Constants,
        private customerApiService: CustomerApiService,
        public modalController: ModalController,
        private fb: FormBuilder
    ) {
        this.createForm = this.fb.group({
            week_days: [[], Validators.required],
            start_hour: ['', Validators.required],
            finish_hour: ['', Validators.required],
            blocked: [false]
        });
    }

    closeModal() {
        this.modalController.dismiss();
    }

    createPriorityTime() {
        this.createForm.value.week_days = this.createForm.value.week_days.map(weekDay => parseInt(weekDay, 10));
        this.modalController.dismiss({data: this.createForm.value});
    }
}
