import {Component, OnInit} from '@angular/core';
import {Constants} from 'src/app/services/constants';
import {Storage} from '@ionic/storage';
import {CustomerApiService} from 'src/app/services/customer/customer-api.service';
import {AlertController, Platform, ToastController, ModalController} from '@ionic/angular';
import {AuthService} from "../../../../services/auth.service";
import {filter} from "rxjs/operators";
import * as moment from "moment";
import { Geolocation } from '@ionic-native/geolocation/ngx';
import {AppointmentCreateModalComponent} from '../../modal/appointment-create-modal/appointment-create-modal.component';
import { AppointmentApiService } from 'src/app/services/company/appointment-api.service';

@Component({
  selector: 'app-past-appointment',
  templateUrl: './past-appointment.page.html',
  styleUrls: ['./past-appointment.page.scss'],
})
export class PastAppointmentPage implements OnInit {
  public id;
  public appointments: any;
  public geolocInterval: any;
  public interval: any;

  constructor(
      private storage: Storage,
      private constants: Constants,
      private appointmentApiService: AppointmentApiService,
      private customerService: CustomerApiService,
      private toast: ToastController,
      private alertCtrl: AlertController,
      private auth: AuthService,
      private modalCtrl: ModalController,
      private toastController: ToastController,
      private platform : Platform,
      private geolocation: Geolocation,
  ) {
  }

  ngOnInit() {
      this.platform.ready().then(() => {
          this.reset();
          this.geolocInterval = setInterval( () => this.getGeolocation(), 5000);
      });
  }

  reset(event = null) {
      this.getCustomers();
      this.getCustomerActiveAppointments(event);
      this.interval = setInterval( () => this.getCustomerActiveAppointments(event), 5000);
  }

  getCustomers() {
      this.storage.get(this.constants.CUSTOMER).then(res => {
          this.id = res;
      });
  }

  getCustomerAppointment() {
      this.auth.getMyCustomerId()
      this.storage.get(this.constants.CUSTOMER).then(res => {
          this.customerService.getCustomerSchedule(res).pipe(filter((appointment: any) => !appointment.cancelation_date && appointment.end < moment.now())).subscribe(result => {
              this.appointments = result;
              for (let index = 0; index < this.appointments.length; index++) {
                  if (Date.parse(this.appointments[index].start) < Date.now()) {
                      this.appointments[index].past = true;
                  }

                  let temp = this.appointments[index].creation_date_year;
                  temp = (this.appointments[index].start).substring(0, 10);
                  temp = temp.split('-');
                  temp.unshift(temp[1]);
                  temp.unshift(temp[3]);
                  temp = temp.splice(0, 3).join('/');
                  this.appointments[index].creation_date_year = temp;
              }
              if (this.appointments.length == 0) {
                  this.presentToast('No tiene ninguna cita');
              }
          });
      });
  }

  getCustomerActiveAppointments(event = null) {
      this.storage.get(this.constants.CUSTOMER).then(res => {
        console.log('llega');
          this.customerService.getCustomerActiveSchedule(res, 'false').subscribe(result => {
              this.appointments = result;
              console.log('llega');
              console.log(result)
              for (let index = 0; index < this.appointments.length; index++) {
                  if (Date.parse(this.appointments[index].start) < Date.now()) {
                      this.appointments[index].past = true;
                  }

                  let temp = this.appointments[index].creation_date_year;
                  temp = (this.appointments[index].start).substring(0, 10);
                  temp = temp.split('-');
                  temp.unshift(temp[1]);
                  temp.unshift(temp[3]);
                  temp = temp.splice(0, 3).join('/');
                  this.appointments[index].creation_date_year = temp;


              }

              if (event != null) {
                  event.target.complete();
              }
          }, err => {
              if (event != null) {
                  event.target.complete();
              }
          });
      });
  }

  async presentToast(message, color = "success") {
      const toast = await this.toast.create({
          message,
          duration: 2000,
          color: color
      });
      toast.present();
  }

  getGeolocation() {
      //Cogemos el token key de las constantes
      this.storage.get(this.constants.TOKEN_KEY).then(token => {
          //Cogemos el customer de las constantes
          this.storage.get(this.constants.USER_ID).then(res => {
              //Declaramos las variables que tienen que llegar a la API
              let coords = {latitude: 0, longitude: 0};
              //Si tiene geolocalizacíon
                  //Si la tiene cogemos la posición en la que se encuentra
                  this.geolocation.getCurrentPosition().then((resp) => {
                      //Guardamos la posicion en la que está
                      coords.latitude = resp.coords.latitude;
                      coords.longitude = resp.coords.longitude;
                      //Llamamos al servicio que llama a la API
                      this.customerService.setGeolocation(token, res, coords).subscribe(result => {
                          // console.log('Llegan las coordenadas: ' + coords);
                          //console.log(res);
                      });
                      //Si tenemos algún error a la hora de coger la posición
                  }, (error) => {
                      var errorMessage;
                      //console.log(error);
                      //Switch para guardar el tipo de error
                      switch (error.code) {
                          case error.PERMISSION_DENIED:
                              errorMessage = 'No se puede acceder a su ubicación, concede permisos a la aplicación.';
                              break;
                          case error.POSITION_UNAVAILABLE:
                              errorMessage = 'Posición no disponible';
                              break;
                          case error.TIMEOUT:
                              errorMessage = 'La petición ha tardado demasiado tiempo en realizarse.';
                              break;
                          default:
                              errorMessage = 'Se ha producido un error al intentar acceder a su ubicación.';
                              break;
                      }
                      //Mostramos el mensaje de error
                      //this.presentToast(errorMessage);
                      //Paramos la ejecución del envio de la localización
                      clearInterval(this.geolocInterval);
                  });
          })
      })
  }

  async confirmDate(indice) {
      const alert = await this.alertCtrl.create({
          header: 'Confirmar cita',
          message: '<strong>Confirma</strong> tu asistencia a la cita.',
          buttons: [
              {
                  text: 'Cancelar',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: (blah) => {
                  }
              }, {
                  text: 'Confirmar',
                  handler: () => {
                      this.confirmationDate(indice);
                  }
              }
          ]
      });
      await alert.present();
  }

  async confirmationDate(indice) {
      this.customerService.setConfirmationDate(this.appointments[indice].appointment_id_dexga).subscribe(result => {
          this.getCustomerActiveAppointments();
      }, err => {
      });
  }

  cancelationDate(appointment, data) {
      data = {
          cancel: true,
          cancelation_reason: data
      }
      this.customerService.setCancelationDate(appointment.appointment_id_dexga, data).subscribe(result => {
          const index = this.appointments.indexOf(appointment);
          this.appointments.splice(index, 1);
          this.presentToast("Su cita ha sido eliminada correctamente.");
      }, err => {
          //console.log(err);
          this.presentToast("Ha ocurrido un error al cancelar su cita", "danger");
      });

      this.storage.get(this.constants.USER_ID).then(res => {
          //Creamos la notificación
          let notification = {
              user_type: 'company',
              user_id: res,
              user_id_send: appointment.schedule.company_id_dexga,
              title: 'Anulación de cita',
              seen: 0,
              description: 'Anular cita para el día ' + (appointment.start).substring(0, 10) +  ' a las ' +
              (appointment.start).substring(((appointment.start).indexOf(' ')+1) , ((appointment.start).indexOf(' ')+6)) + ' en la agenda de ' + appointment.schedule.name + '.'
          }

          this.appointmentApiService.createNotification(res, notification).subscribe(result => {
          }, error => {
          })
      });    
  }

  async presentAlertPrompt(appointment) {
      const alert = await this.alertCtrl.create({
          header: 'Anular cita',
          inputs: [
              {
                  name: 'cancelation_reason',
                  placeholder: 'Motivo (Opcional)'
              }
          ],
          buttons: [
              {
                  text: 'Volver',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: () => {
                  }
              }, {
                  text: 'Anular cita',
                  handler: (cancelation_reason) => {
                      if (cancelation_reason == '') {
                          cancelation_reason = null;
                      }
                      this.confirmCancelAppointment(appointment, cancelation_reason['cancelation_reason']);
                  }
              }
          ]
      });
      await alert.present();
  }

  async confirmCancelAppointment(appointment, cancelation_reason) {
      const alert = await this.alertCtrl.create({
          header: '¿Está seguro de que desea anular la cita?',
          buttons: [
              {
                  text: 'Volver',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: () => {
                  }
              }, {
                  text: 'Anular cita',
                  handler: () => {
                      if (cancelation_reason == '') {
                          cancelation_reason = null;
                      }this.cancelationDate(appointment, cancelation_reason);
                  }
              }
          ]
      });
      await alert.present();
  }

  openInsertModal() {
      return this.modalCtrl.create({
          component: AppointmentCreateModalComponent
      }).then(modal => {
          modal.present();
          modal.onDidDismiss().then(data => {
              if (data.data == null) {
                  return;
              }
              //console.log(data);
              // this.customerService.savePriorityTime(this.id, data.data.data).subscribe((response: any) => {
              //     for (const priorityTime of response) {
              //         priorityTime.week_day = this.weekDay[(priorityTime.week_day) - 1];
              //         priorityTime.start_hour = moment(priorityTime.start_hour).format('HH:mm');
              //         priorityTime.finish_hour = moment(priorityTime.finish_hour).format('HH:mm');
              //         this.priorities.push(priorityTime);
              //     }
              //     this.presentToast('Horario de preferencia añadido con éxito', 'success');
              // }, error => {
              //     console.log(error);
              //     this.presentToast('Ha ocurrido un error al añadir el horario de preferencia', 'danger');
              // });
          });
      });
  }
}

