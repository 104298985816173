import {Component, Input, OnInit} from '@angular/core';
import {PriorityTime} from '../../../../models/customer/PriorityTime';
import {Storage} from '@ionic/storage';
import {Constants} from '../../../../services/constants';
import {CustomerApiService} from '../../../../services/customer/customer-api.service';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-priority-time-edit-modal',
    templateUrl: './priority-time-edit-modal.component.html',
    styleUrls: ['./priority-time-edit-modal.component.scss'],
})
export class PriorityTimeEditModalComponent implements OnInit {

    @Input() priorityTime: PriorityTime;

    editForm: FormGroup;

    constructor(
        private storage: Storage,
        private constants: Constants,
        private customerApiService: CustomerApiService,
        public modalController: ModalController,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.editForm = this.fb.group({
            week_day: [this.priorityTime.week_day, [Validators.required]],
            start_hour: [this.priorityTime.start_hour, [Validators.required]],
            finish_hour: [this.priorityTime.finish_hour, [Validators.required]],
            blocked: [this.priorityTime.blocked]
        });
    }

    closeModal() {
        this.modalController.dismiss();
    }

    update() {
        this.modalController.dismiss({data: this.editForm.value});
    }
}
