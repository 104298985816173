import {Component, OnInit} from '@angular/core';
import { AppointmentPage } from './appointment/appointment.page';
import { PastAppointmentPage } from './past-appointment/past-appointment.page';

@Component({
    selector: 'app-customer-dashboard',
    templateUrl: './customer-dashboard.page.html',
    styleUrls: ['./customer-dashboard.page.scss'],
})
export class CustomerDashboardPage implements OnInit {
    
  appointmentPage = AppointmentPage;
  
  pastAppointmentPage = PastAppointmentPage;
  
  constructor() { }

  ngOnInit() { 
  }
}
