import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import {FormControl, FormGroup} from "@angular/forms";
import { Storage } from '@ionic/storage';
import { Constants } from 'src/app/services/constants';
import { CustomerApiService } from 'src/app/services/customer/customer-api.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
})
export class AddAddressComponent implements OnInit {

  form : any;

  constructor(
    public modalController: ModalController,
    private storage: Storage,
    private constants: Constants,
    private toast: ToastController,
    private customerService: CustomerApiService,
  ) { }

  ngOnInit() {
    this.form = new FormGroup({
      street: new FormControl(''),
      number: new FormControl(''),
      stair: new FormControl(''),
      city: new FormControl('')
    });
  }

  closeModal() {
    this.modalController.dismiss();
  }

  saveAddress(){
    var error = false;
    if (this.form.value.street == "") {
      this.presentToast("Se necesita introducir una calle");
      error = true;
    } else if (this.form.value.number == "") {
      this.presentToast("Se necesita introducir un número");
      error = true;
    } else if (this.form.value.city == "") {
      this.presentToast("Se necesita poner una ciudad");
      error = true;
    }

    if (!error) {
      this.storage.get(this.constants.CUSTOMER).then(res => {
        var data = {};
        data = {
          customer_id : res,
          street : this.form.value.street,
          city : this.form.value.city,
          number : this.form.value.number,
          stair : this.form.value.stair
        }
        this.customerService.setAddress(data).subscribe(result => {
          console.log(result);
          this.modalController.dismiss();
        });
      });

      

    }
    
  }

  async presentToast(message) {
    const toast = await this.toast.create({
        message,
        duration: 2000
    });
    toast.present();
  }

}
