import {Component, OnInit} from '@angular/core';
import {Storage} from '@ionic/storage';
import {Constants} from '../services/constants';
import {MenuController, Platform} from '@ionic/angular';
import {Router} from '@angular/router';
import { AdminDashboardPage } from '../pages/admin/admin-dashboard.page';
import { CustomerDashboardPage } from '../pages/customer/customer-dashboard/customer-dashboard.page';
import { CompanyDashboardPage } from '../pages/company/company-dashboard/company-dashboard.page';
import { BehaviorSubject } from 'rxjs';
import { CompanyApiService } from '../services/company/company-api.service';
import { CompanyMenu } from '../pages/company/company-menu';
import { CustomerMenu } from '../pages/customer/customer-menu';
import { AdminMenu } from '../pages/admin/admin-menu';

//import {FCM} from '@ionic-native/fcm/ngx';


@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
    welcomeNameState = new BehaviorSubject(null);

    constructor(
        public menuCtrl: MenuController,
        private constants: Constants,
        private storage: Storage,
        private router: Router,
        //private fcm: FCM,
        private platform: Platform
    ) { }

    ngOnInit() {
        this.ionViewWillEnter(); 
        this.checkLogin();
        // this.fcm.getToken().then(token => {
        //     //backend.registerToken(token);
        //     console.log("TOKEN" + token);
        // });
    } 
    
    ionViewWillEnter() {
        //this.menuCtrl.enable(true);
    }

    checkLogin() {
        this.storage.get(this.constants.TOKEN_KEY).then(res => {
            if (res == null) {
                this.router.navigateByUrl("/login");
            } else {
                this.checkRole();
            }
        });
    }

    checkRole() {
        this.storage.get(this.constants.USER_ROLE).then(async res => {
            switch (res) {
                case 'customer':
                    this.router.navigateByUrl("/cliente");
                    break;
                case 'company':
                    this.router.navigateByUrl("/tienda/principal");
                    break;
                case 'admin':
                    this.router.navigateByUrl("/admin");
                    break;
                default:
                    this.router.navigateByUrl("/login");
                    break;
            }
        });
      }


}
