import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Component({
    selector: 'app-popover-config',
    templateUrl: './popover-config.component.html',
    styleUrls: ['./popover-config.component.scss'],
})
export class PopoverConfigComponent implements OnInit {
    public isAdmin: boolean;
    public isCompany: boolean;
    public isUser: boolean;

    constructor(
        private router: Router,
        private auth: AuthService,
    ) {
    }

    ngOnInit() {
        this.auth.getRole().then(role => {
            this.isAdmin = role === 'admin';
            this.isCompany = role === 'company';
            this.isUser = role === 'customer';
        });
    }

    liberate() {
        this.router.navigateByUrl('admin/liberar-ip');
    }

    editUser() {
        if (this.isAdmin) {
            this.router.navigateByUrl('admin/cuenta');
        }

        if (this.isCompany) {
            this.router.navigateByUrl('tienda/cuenta');
        }

        if(this.isUser){
            this.router.navigateByUrl('cliente/cuenta');
        }
    }
}
