import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-edit-appointment-modal',
  templateUrl: './edit-appointment-modal.component.html',
  styleUrls: ['./edit-appointment-modal.component.scss'],
})
export class EditAppointmentModalComponent implements OnInit {
  public data;
  constructor(
    private modalController: ModalController,
    private router: Router,
  ) { }

  ngOnInit() {
    console.log(this.data);
  }

  cancel() {
    this.modalController.dismiss({
        'dismissed': true
    });
  }

  openCalendar(){
    this.modalController.dismiss({
        'dismissed': true
    });
      this.router.navigateByUrl('tienda/principal');
  }

}
