import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CustomerApiService } from 'src/app/services/customer/customer-api.service';

@Component({
  selector: 'app-edit-order-modal',
  templateUrl: './edit-order-modal.component.html',
  styleUrls: ['./edit-order-modal.component.scss'],
})
export class EditOrderModalComponent implements OnInit {
  public data;
  constructor(
    private modalController: ModalController,
    private router: Router,
    private customerApiService: CustomerApiService
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.getPedido();
  }

  cancel() {
    this.modalController.dismiss({
        'dismissed': true
    });
  }

  openCalendar(){
    this.modalController.dismiss({
        'dismissed': true
    });
      this.router.navigateByUrl('tienda/principal');
  }

  getPedido(){
    this.data.pedido = this.data.description.substring(this.data.description.search('un pedido de')+12);
    this.data.description = this.data.description.substring(0, this.data.description.search('un pedido de'));
    var numero = 0;
    if (this.data.description.search('Recoger') != 0 && this.data.description.search('Recoger') != -1) {
      numero = this.data.description.search('Recoger');
    }else{
      numero = this.data.description.search('Entrega')
    }
    this.data.time = this.data.description.substring((this.data.description.search('para ') + 5), numero);
  }

  modify(type){
    var id = this.data.order_id;
    var id2 = this.data.notification_id_dexga;
    console.log(id);
    var data;
    var data2;
    if (type == 'accept') {
      data  = {
        accept : 1
      }
      data2  = {
        title : 'Pedido aceptado'
      }
    }else if (type == 'cancel') {
      data  = {
        accept : 0
      }
      data2  = {
        title : 'Pedido cancelado'
      }
    }
    this.customerApiService.editOrder(id,data).subscribe(result => {
      console.log(result);
    });
    this.customerApiService.editNotification(id2,data2).subscribe(result => {
      console.log(result);
    });

    console.log(this.data);
    
    let notification = {
      user_type: 'customer',
      user_id: this.data.user_id_send,
      user_id_send: this.data.user_id_dexga,
      title: data2['title'],
      seen: 0,
      description: this.data.description,
      alias: this.data.alias,
      start: this.data.start,
      finish: this.data.finish,
      phone: this.data.phone
  }

console.log(notification);

    this.customerApiService.createNotification(this.data.user_id_send,notification).subscribe(result => {
      console.log(result);
      this.modalController.dismiss({
        'dismissed': true
      }); 
    });
    
  }

}
