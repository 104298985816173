import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {IndexComponent} from './index/index.component';
import {ResolverService} from './services/company/resolver.service';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'index',
        pathMatch: 'full'
    },
    {
        path: 'index',
        component: IndexComponent
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
    },
    // Se comenta pero está funcional por si en un futuro se necesita usar
    // {
    //     path: 'register',
    //     loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule)
    // },
  
    {
      path: 'politica-privacidad',
      loadChildren: () => import('./pages/admin/privacity/privacity.module').then( m => m.PrivacityPageModule)
    },
    {
        path: 'admin',
        loadChildren: () => import('./pages/admin/admin-dashboard.module').then(m => m.AdminDashboardPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'admin'
        }
    },
    {
        path: 'tienda/principal',
        loadChildren: () => import('./pages/company/company-dashboard/company-dashboard.module').then(m => m.CompanyDashboardPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'company'
        }
    },
    {
        path: 'tienda/agendas',
        loadChildren: () => import('./pages/company/schedule-list/schedule-list.module').then(m => m.ScheduleListPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'company'
        }
    },
    {
        path: 'tienda/buscar',
        loadChildren: () => import('./pages/company/customer-search/customer-search.module').then(m => m.CustomerSearchPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'company'
        }
    },
    {
        path: 'tienda/cita/disponibilidad/:contact',
        resolve: {
            contact: ResolverService
        },
        loadChildren: () => import('./pages/company/customer-availability/customer-availability.module').then(m => m.CustomerAvailabilityPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'company'
        }
    },
    {
        path: 'tienda/clientes',
        loadChildren: () => import('./pages/company/contact-book-list/contact-book-list.module').then(m => m.ContactBookListPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'company'
        }
    },
    {
        path: 'tienda/cuenta',
        loadChildren: () => import('./pages/company/edit-account/edit-account.module').then(m => m.EditAccountPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'company'
        }
    },
    {
      path: 'tienda/notificaciones',
      loadChildren: () => import('./pages/company/notification-company/notification-company.module').then( m => m.NotificationCompanyPageModule),
      canActivate: [AuthGuard],
        data: {
            expectedRole: 'company'
        }
    },
    {
        path: 'cliente',
        loadChildren: () => import('./pages/customer/customer-dashboard/customer-dashboard.module').then(m => m.CustomerDashboardPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'customer'
        }
    },
    {
        path: 'cliente/cuenta',
        loadChildren: () => import('./pages/customer/edit-account-customer/edit-account-customer.module').then(m => m.EditAccountCustomerPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'customer'
        }
    },
    {
        path: 'admin/cliente',
        loadChildren: () => import('./pages/admin/customer/customer.module').then(m => m.ClientePageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'admin'
        }
    },
    {
        path: 'admin/tienda',
        loadChildren: () => import('./pages/admin/company/company.module').then(m => m.TiendaPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'admin'
        }
    },
    {
        path: 'admin/crear-tienda',
        loadChildren: () => import('./pages/admin/create-company/create-company.module').then(m => m.CrearTiendaPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'admin'
        }
    },
    {
        path: 'admin/crear-cliente',
        loadChildren: () => import('./pages/admin/create-customer/create-customer.module').then(m => m.CreateCustomerPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'admin'
        }
    },
    {
        path: 'admin/citas',
        loadChildren: () => import('./pages/admin/appointment/appointment.module').then(m => m.AppointmentPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'admin'
        }
    },
    {
        path: 'admin/editar-cliente',
        loadChildren: () => import('./pages/admin/edit-customer/edit-customer.module').then(m => m.EditCustomerPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'admin'
        }
    },
    {
        path: 'admin/editar-tienda',
        loadChildren: () => import('./pages/admin/edit-company/edit-company.module').then(m => m.EditCompanyPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'admin'
        }
    },
    {
        path: 'admin/liberar-ip',
        loadChildren: () => import('./pages/admin/liberate-ip/liberate-ip.module').then(m => m.LiberateIpPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'admin'
        }
    },
    {
        path: 'admin/cuenta',
        loadChildren: () => import('./pages/admin/edit-account/edit-account.module').then(m => m.EditAccountPageModule),
        canActivate: [AuthGuard],
        data: {
            expectedRole: 'admin'
        }
    },
    {
        path: '**',
        redirectTo: 'index'
    },
  {
    path: 'edit-account',
    loadChildren: () => import('./pages/company/edit-account/edit-account.module').then( m => m.EditAccountPageModule)
  },
  {
    path: 'edit-account-customer',
    loadChildren: () => import('./pages/customer/edit-account-customer/edit-account-customer.module').then( m => m.EditAccountCustomerPageModule)
  },
  {
    path: 'edit-account-customer',
    loadChildren: () => import('./pages/customer/edit-account-customer/edit-account-customer.module').then( m => m.EditAccountCustomerPageModule)
  },
  {
    path: 'directory',
    loadChildren: () => import('./pages/customer/directory/directory.module').then( m => m.DirectoryPageModule),
    canActivate: [AuthGuard],
        data: {
            expectedRole: 'customer'
        }
  },
  {
    path: 'companies',
    loadChildren: () => import('./pages/customer/companies/companies.module').then( m => m.CompaniesPageModule)
  },
  {
    path: '#informationNav',
    loadChildren: () => import('./pages/customer/modal/company-modal/information/information.module').then( m => m.InformationPageModule)
  },
  {
    path: '#catalogueNav',
    loadChildren: () => import('./pages/customer/modal/company-modal/catalogue/catalogue.module').then( m => m.CataloguePageModule)
  },
  {
    path: '#saleNav',
    loadChildren: () => import('./pages/customer/modal/company-modal/sale/sale.module').then( m => m.SalePageModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./pages/customer/order/order.module').then( m => m.OrderPageModule)
  },
  {
    path: 'flash-offer',
    loadChildren: () => import('./pages/customer/flash-offer/flash-offer.module').then( m => m.FlashOfferPageModule)
  }



];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRoutingModule {

    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

}
