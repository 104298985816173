import { Injectable } from '@angular/core';
import { Constants } from './constants';
import { Storage } from '@ionic/storage';
import { CompanyApiService } from './company/company-api.service';
import { CustomerApiService } from './customer/customer-api.service';


@Injectable({
  providedIn: 'root'
})
export class GetWelcomeNameService {
    name = null;
    constructor(
        private storage: Storage,
        private constants: Constants,
        private companyApiService: CompanyApiService,
        private customerApiService: CustomerApiService
    ) { }

    getSettings(): Promise<{}> {
        return new Promise((resolve) => {
            if (this.name !== null) {
                resolve(this.name);
            } else {
                this.storage.get(this.constants.USER_ROLE).then(async (data) => {
                    switch (data) {
                        case "customer":
                            this.storage.get(this.constants.TOKEN_KEY).then(token =>{
                                this.customerApiService.getMyId().subscribe(result => {
                                    this.name = result['name'];
                                    resolve(this.name);  
                                }, error => {
                                    console.log(error);
                                });
                            });
                        break;
                  
                        case "company":
                            this.storage.get(this.constants.TOKEN_KEY).then(token =>{
                                this.companyApiService.getMyId(token).subscribe(result => {
                                    this.name = result['registered_name'];
                                    resolve(this.name);  
                                }, error => {
                                    console.log(error);
                                });
                            });
                        break;
                  
                        case "admin":
                            this.name = "Admin";
                            resolve(this.name);
                        break;
                      }
                });
            }
        });
    }
}
