import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {GetWelcomeNameService} from 'src/app/services/get-welcome-name.service';
import {AlertController} from "@ionic/angular";


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    name = null;

    constructor(
        private auth: AuthService,
        private getWelcomeNameService: GetWelcomeNameService,
        private alertCtrl: AlertController
    ) {
        this.getData();
    }


    ngOnInit() {
        this.getWelcomeNameService.getSettings();
    }

    getData() {
        this.getWelcomeNameService.getSettings().then((name) => {
            this.name = name;
        });
    }

    async logOut() {
        const alert = await this.alertCtrl.create({
            header: 'Cerrar sesión actual',
            buttons: [
                {
                    text: 'Volver',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                }, {
                    text: 'Cerrar sesión',
                    handler: () => {
                        this.auth.logout();
                    }
                }
            ]
        });
        await alert.present();
    }
}
