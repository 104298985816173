import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TrimValidator} from 'src/app/validation/trim.validation';

@Component({
    selector: 'app-edit-schedule-modal',
    templateUrl: './edit-schedule-modal.component.html',
    styleUrls: ['./edit-schedule-modal.component.scss'],
})
export class EditScheduleModalComponent implements OnInit {
    @Input() id: string;
    @Input() scheduleName: string;

    scheduleForm: FormGroup;
    isSubmitted = false;

    constructor(
        private modalCtrl: ModalController,
        public formBuilder: FormBuilder
    ) {
    }

    get errorControl() {
        return this.scheduleForm.controls;
    }

    ngOnInit() {
        this.scheduleForm = this.formBuilder.group({
            name: [this.scheduleName, Validators.compose([TrimValidator.isValid, Validators.maxLength(30), Validators.required])]
        })
    }

    key() {
        this.isSubmitted = true;
    }

    submitForm() {
        this.isSubmitted = true;
        if (!this.scheduleForm.valid) {
            return false;
        } else {
            this.modalCtrl.dismiss({
                id: this.id,
                name: this.scheduleForm.value.name.trim(),
                order: true
            });

        }
    }

    cancel() {
        this.modalCtrl.dismiss();
    }
}
