import { Component, OnInit } from '@angular/core';
import { Storage } from "@ionic/storage";
import { Company } from 'src/app/models/admin/Company';
import { Constants } from 'src/app/services/constants';
import { CustomerApiService } from 'src/app/services/customer/customer-api.service';


@Component({
  selector: 'app-information',
  templateUrl: './information.page.html',
  styleUrls: ['./information.page.scss'],
})
export class InformationPage implements OnInit {
  
  public ready = false;
  public company: Company[];
  mapSrc: any;

  constructor(
    private storage:Storage,
    private _peti: CustomerApiService,
    private constants: Constants
  ) { }

  ngOnInit() {
    this.getCompany();
  }


  getCompany(){
    this.storage.get('company').then(res => {
      this.company = res;
      if (this.company['open_hour_1']) {
      this.company['open_hour_1'] = res.open_hour_1.substring(11,16);
      this.company['close_hour_1'] = res.close_hour_1.substring(11,16);
      }
      if (this.company['open_hour_2']) {
        this.company['open_hour_2'] = res.open_hour_2.substring(11,16);
        this.company['close_hour_2'] = res.close_hour_2.substring(11,16);
      }
      
      console.log(res);
     
      this.getLocation(this.company);
    });
  }

  getLocation(company){
    this.storage.get(this.constants.TOKEN_KEY).then(token => {
      this._peti.getCompanyLocation(token, company['company_id_dexga']).subscribe(result => {
        console.log(result);
        this.mapSrc = 'https://maps.google.com/maps?q=' + result['latitude'] + '%20'+ result['longitude'] + '&t=&z=13&ie=UTF8&iwloc=&output=embed';
      });
    });
    this.ready = true;
  }



}
