import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MobileHeaderComponent} from 'src/app/components/mobile-header/mobile-header.component';
import {IonicModule} from '@ionic/angular';


@NgModule({
  declarations: [
    MobileHeaderComponent
  ],
  exports: [
    MobileHeaderComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MobileHeaderModule { }
