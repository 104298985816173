import {Component, Input, OnInit} from '@angular/core';
import {ModalController, ToastController} from "@ionic/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import * as moment from 'moment';
import {AppointmentApiService} from "../../services/company/appointment-api.service";

@Component({
    selector: 'app-edit-modal',
    templateUrl: './edit-modal.component.html',
    styleUrls: ['./edit-modal.component.scss'],
})
export class EditModalComponent implements OnInit {

    @Input() eventNew;
    @Input() eventOld;
    @Input() schedules;
    @Input() companyId;

    formGroup: FormGroup;
    loading: boolean = false;

    oldSchedule;

    oldStart;
    oldEnd;

    constructor(private modalController: ModalController,
                private fb: FormBuilder,
                private appointmentApiService:AppointmentApiService,
                private toastController:ToastController) {
    }

    ngOnInit() {
        //Asignar el titulo del anterior schedule a la var oldSchedule para utilizarla en el html.
        let schedule;
        for(schedule of this.schedules){
            if(schedule.id==this.eventOld.event.resourceIds[0]){
                this.oldSchedule=schedule;
                break;
            }
        }

        this.oldStart=moment(this.eventOld.range.start).toISOString();
        this.oldEnd=moment(this.eventOld.range.end).toISOString();

        //console.log(this.eventNew.range.start+" "+this.eventNew.range.end);
        this.formGroup=this.fb.group({
            start:[moment(this.eventNew.range.start).toISOString(),[Validators.required]],
            end:[moment(this.eventNew.range.end).toISOString(),[Validators.required]],
            schedule:[this.eventNew.event.resourceIds[0],[Validators.required]],
            comment:[this.eventNew.event.extendedProps.appointment.comment]
        });

        /*this.formGroup=this.fb.group({
            start:[this.newEvent.start,[Validators.required]],
            end:[this.newEvent.end,[Validators.required]],
        });*/
    }

    editAppointment() {
        let appointment={
            schedule_id:this.formGroup.value.schedule,
            customer_id:this.eventNew.event.extendedProps.appointment.customer.customer_id_dexga,
            name:this.eventNew.event.extendedProps.appointment.name,
            comment:this.formGroup.value.comment,
            start:this.formGroup.value.start,
            end:this.formGroup.value.end,
            id:this.eventNew.event.publicId
        }


        this.loading=true;
        this.appointmentApiService.editAppointment(this.companyId,this.eventOld.event.resourceIds[0],this.eventNew.event.publicId,appointment).subscribe(
            result=>{
                result['alias']=this.eventOld.event['title'].substring(0, (this.eventOld.event['title'].indexOf('|'))-1),
                this.modalController.dismiss({'accept':true,'data':result});
                this.presentToast("Cita modificada correctamente","primary");
                console.log(result)
            },erro=>{
                this.presentToast("Ha ocurrido un error","danger");
                console.error(erro);
            },()=>{
                this.loading=false;
            });
    }

    dismiss() {
        this.modalController.dismiss({
            'accept': false
        });
    }

    presentToast(msg: string, color: string) {
        this.toastController.create({
            color,
            message: msg,
            duration: 4000
        }).then(toast=>toast.present());
    }

}
