import {FormControl} from '@angular/forms';

export class TrimValidator {
    static isValid(fc: FormControl) {
        if (fc.value.trim() === '') {
            return ({trim: true});
        } else {
            return (null);
        }
    }
}
