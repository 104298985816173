import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../constants';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdminApiService {

    public url: string;
    public var: string;

    constructor(
        private constants: Constants,
        public _http: HttpClient,
    ) {
    }

    leavingCustomer(token, idCliente): Observable<any> {
        var data = {
            'leave': true
        };
        return this._http.patch(this.constants.API_ROUTE + 'customers/' + idCliente, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    reactivateCustomer(token, idCliente): Observable<any> {
        var data = {
            'reactivate': 'true'
        };
        return this._http.patch(this.constants.API_ROUTE + 'customers/' + idCliente, data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }

        });
    }

    reactivateCompany(token, idTienda): Observable<any> {
        return this._http.patch(this.constants.API_ROUTE + 'company/reactivate/' + idTienda, '', {
            headers: {
                'Authorization': 'Bearer ' + token
                // 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1OTExODMzNjYsImV4cCI6MTU5Mzc3NTM2NiwiZGF0YSI6eyJpZCI6Nn19.99m3q_m2gVGdn-4u-bIw4uBqLfxhsZ2K-aFrdhz_aIQ'
            }

        });
    }

    leavingCompany(token, idTienda): Observable<any> {
        return this._http.patch(this.constants.API_ROUTE + 'company/leaving/' + idTienda, '', {
            headers: {
                'Authorization': 'Bearer ' + token
                // 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1OTExODMzNjYsImV4cCI6MTU5Mzc3NTM2NiwiZGF0YSI6eyJpZCI6Nn19.99m3q_m2gVGdn-4u-bIw4uBqLfxhsZ2K-aFrdhz_aIQ'
            }

        });
    }

    getAllCompanyFiltered(value): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'companies?brand_name=' + value);

    }

    getFilteredAppointment(value, customer, creation_date, leaving_date): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'filterAppointment?value=' + value + '&customer=' + customer + '&start=' + creation_date + '&end=' + leaving_date, {});
    }

    getACustomer(id): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'customers/' + id, {});
    }

    getACompany(id): Observable<any> {

        return this._http.get(this.constants.API_ROUTE + 'companies/' + id);
    }

    getCompanyUser(companyId): Observable<any> {
        return this._http.get(`${this.constants.API_ROUTE}companies/${companyId}/user`);
    }

    getUserData(token, peticion): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'user?' + peticion, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    editUserData(id, data): Observable<any> {
        return this._http.patch(this.constants.API_ROUTE + 'users/' + id, data);
    }

    getAllCompany(token?): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'companies');

    }

    getAllSchedule(token, idTienda): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'companies/' + idTienda + '/schedules' + idTienda);
    }

    getAllPriority(token, idcliente): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'customers/' + idcliente + '/priorityTime', {
            headers: {
                'Authorization': 'Bearer ' + token
                // 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1OTExODMzNjYsImV4cCI6MTU5Mzc3NTM2NiwiZGF0YSI6eyJpZCI6Nn19.99m3q_m2gVGdn-4u-bIw4uBqLfxhsZ2K-aFrdhz_aIQ'
            }

        });
    }

    getKPIS() {
        return this._http.get(this.constants.API_ROUTE + 'kpis');
    }

    cita(token, idcliente): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'customers/' + idcliente + '/appointments', {
            headers: {
                'Authorization': 'Bearer ' + token
                // 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1OTExODMzNjYsImV4cCI6MTU5Mzc3NTM2NiwiZGF0YSI6eyJpZCI6Nn19.99m3q_m2gVGdn-4u-bIw4uBqLfxhsZ2K-aFrdhz_aIQ'
            }

        });
    }

    getACustomerText(token, data): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'customers?phone=' + data.value, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    editCompany(idcliente, data): Observable<any> {
        return this._http.patch(this.constants.API_ROUTE + 'companies/' + idcliente, data);
    }

    createCustomer(token, data): Observable<any> {
        return this._http.post(this.constants.API_ROUTE + 'customers', data, {
            headers: {
                'DONQ-Alive': '' + token
            }
        });
    }

    createCompany(token, data): Observable<any> {
        return this._http.post(this.constants.API_ROUTE + 'companies', data, {
            headers: {
                'DONQ-Alive': '' + token
            }
        });
    }

    editCustomer(id, data): Observable<any> {
        return this._http.patch(this.constants.API_ROUTE + 'customers/' + id, data);
    }

    editPriority(token, idpriority, data): Observable<any> {
        console.log('--------------------------------------');
        console.log(data);

        return this._http.patch(this.constants.API_ROUTE + 'priorityTime/' + idpriority, data, {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        });
    }

    getAllCustomer(token): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'customers', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    getAllCustomersFiltered(value): Observable<any> {
        return this._http.get(this.constants.API_ROUTE + 'customers?phone=' + value);
    }

    updateSchedule(token, request) {
        return this._http.patch(this.constants.API_ROUTE + 'schedule/update/' + request.id, request, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    insertSchedule(token, request) {
        return this._http.post(this.constants.API_ROUTE + 'schedule/add', request, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    liberateIp(ipv6, blocked) {
        return this._http.patch(this.constants.API_ROUTE + 'ip-secure/' + ipv6, {'ban': blocked});
    }

    createHashtags(token, request){
        return this._http.post(this.constants.API_ROUTE + 'keywords', request, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    getHashtags(token){
        return this._http.get(this.constants.API_ROUTE + 'keywords', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    deleteHashtags(token){
        return this._http.delete(this.constants.API_ROUTE + 'keywords', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    createCatalogue(formData, token){
        console.log(formData.get("file"));
        console.log(token);
        return this._http.post(this.constants.API_ROUTE + 'catalogue', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'enctype': 'multipart/form-data;'
            }
        })
    }

    updateCompanyPhoto(formData, token){
        console.log(formData.get("file"));
        console.log(token);
        return this._http.post(this.constants.API_ROUTE + 'company/photo', formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'enctype': 'multipart/form-data;'
            }
        })
    }

    getCatalogues(token, idTienda){
        return this._http.get(this.constants.API_ROUTE + 'catalogue/' + idTienda , {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    getNumberCatalogues(token, idTienda){
        return this._http.get(this.constants.API_ROUTE + 'numberCatalogue/' + idTienda , {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    deleteCatalogues(token, idCatalogo){
        return this._http.delete(this.constants.API_ROUTE + 'catalogue/' + idCatalogo , {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    getFlash(token, customerId){
        return this._http.get(this.constants.API_ROUTE + 'flash/' + customerId , {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    deleteOffer(token, idFlash){
        return this._http.delete(this.constants.API_ROUTE + 'flash/' + idFlash , {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    setOffer(token, data){
        return this._http.post(this.constants.API_ROUTE + 'flash' , data, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    setOfferPhoto(formData, token, idFlash){
        console.log(formData.get("file"));
        console.log(token);
        return this._http.post(this.constants.API_ROUTE + 'flash/photo/' + idFlash, formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'enctype': 'multipart/form-data;'
            }
        })
    }
}
