import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Constants } from 'src/app/services/constants';
import { CustomerApiService } from 'src/app/services/customer/customer-api.service';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss'],
})
export class EditAddressComponent implements OnInit {

  public id;
  public ready = false;
  public address;

  constructor(
    public modalController: ModalController,
    private storage: Storage,
    private constants: Constants,
    private toast: ToastController,
    private customerService: CustomerApiService,
    private alertCtrl: AlertController,
  ) { }

  ngOnInit() {
    console.log("llega al edit");
    this.getAddress();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  getAddress() {
    this.storage.get(this.constants.CUSTOMER).then(res => {
      this.id = res;
      this.customerService.getAddress(res).subscribe(result => {
        this.address = result;
        this.ready = true;
        if (this.address.length === 0) {
          this.modalController.dismiss();
        }
        console.log(result);
        console.log(this.ready);
      })
    });
  }

  confirmBorrarDireccion(id){
    this.alertCtrl.create({
      cssClass: '',
      header: 'Borrar dirección',
      message: '¿Está seguro de borrar esta <strong>dirección</strong>?',
      buttons: [
          {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                //  console.log('Confirm Cancel: blah');
              }
          }, {
              text: 'Borrar',
              handler: () => {
                  this.borrarDireccion(id);
                  this.getAddress();
              }
          }
      ]
    }).then(alert => alert.present());
  }

  borrarDireccion(id){
    this.customerService.deleteAddress(id).subscribe(result => {
      console.log(result);
    });
  }

  confirmGuardarCambios(){
    this.alertCtrl.create({
      cssClass: '',
      header: 'Guardar cambios',
      message: '¿Está seguro de guardar los <strong>cambios realizados</strong>?',
      buttons: [
          {
              text: 'Cancelar',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
                //  console.log('Confirm Cancel: blah');
              }
          }, {
              text: 'Guardar',
              handler: () => {
                  this.guardarDirecciones();
              }
          }
      ]
    }).then(alert => alert.present());
  }

  guardarDirecciones(){
    
    var error = false;
    var arrData = [];
    for (let index = 0; index < this.address.length; index++) {
      var calle = (<HTMLInputElement>document.getElementById("street"+this.address[index]["address_id"])).value;
      var numero = (<HTMLInputElement>document.getElementById("number"+this.address[index]["address_id"])).value;
      var escalera = (<HTMLInputElement>document.getElementById("stair"+this.address[index]["address_id"])).value;
      var ciudad = (<HTMLInputElement>document.getElementById("city"+this.address[index]["address_id"])).value;
      
      if (calle == "") {
        this.presentToast("Se necesita introducir una calle");
        error = true;
      } else if (numero == "") {
        this.presentToast("Se necesita introducir un número");
        error = true;
      } else if (ciudad == "") {
        this.presentToast("Se necesita poner una ciudad");
        error = true;
      }

      if (!error) {
        var data = {};
        data = {
          customer_id : this.id,
          street : calle,
          city : ciudad,
          number : numero,
          stair : escalera
        }
        arrData.push(data);
      }
    }

    if ((!error)) {

      //Borramos todos los datos que tenía este usuario
      this.customerService.deleteAllAddress(this.id).subscribe(result => {
        console.log(result);
      });

      //Guardamos los nuevos datos que ha introducido
      for (let index = 0; index < arrData.length; index++) {
        this.customerService.setAddress(arrData[index]).subscribe(result => {
          console.log(result);
        });
      }
      this.modalController.dismiss();
    }
  }

  async presentToast(message) {
    const toast = await this.toast.create({
        message,
        duration: 2000
    });
    toast.present();
  }

}
