import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ContactBook} from "../../../models/company/contactbook";
import {ContactBookApiService} from "../../../services/company/contact-book-api.service";
import {AuthService} from "../../../services/auth.service";


@Component({
    selector: 'app-company-dashboard',
    templateUrl: './company-dashboard.page.html',
    styleUrls: ['./company-dashboard.page.scss'],
})
export class CompanyDashboardPage implements OnInit {

    contacts: ContactBook[];
    filterResultDataSet:ContactBook[];
    notFound:boolean=false;
    processing:boolean=false;

    constructor(private contactBookApiService: ContactBookApiService,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        this.authService.getMyCompanyIdWell().then(companyId => {

            this.contactBookApiService.getContacts(companyId).subscribe(result => {
                var resultFiltered = [];
                for (let index = 0; index < result.length; index++) {
                    if (result[index].leaving_date != null) {
                    }else{
                        resultFiltered.push(result[index])
                    }
                    
                }
                    this.contacts = resultFiltered;
                });
        });
    }
}
