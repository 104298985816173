import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {Constants} from "../../../../services/constants";
import {Storage} from "@ionic/storage";
import {CustomerApiService} from '../../../../services/customer/customer-api.service';
import { SalePage } from './sale/sale.page';
import { InformationPage } from './information/information.page';
import { CataloguePage } from './catalogue/catalogue.page';
import {AppointmentCreateModalComponent} from '../../modal/appointment-create-modal/appointment-create-modal.component';
import {OrderModalComponent} from '../../modal/order-modal/order-modal.component';

@Component({
  selector: 'app-company-modal',
  templateUrl: './company-modal.component.html',
  styleUrls: ['./company-modal.component.scss'],
})
export class CompanyModalComponent implements OnInit {
  salePage = SalePage;
  informationPage = InformationPage;
  cataloguePage= CataloguePage;
  pageData : any;
  company;
  public keywords;
  public ready;

  constructor(
    public modalController: ModalController,
    private constants:Constants,
    private storage:Storage,
    private _peti: CustomerApiService,
    public navParams: NavParams,
    private modalCtrl: ModalController
  ) { 
    this.pageData = {'username': "David" };
  }

  ngOnInit() {
    console.log(this.company);
    this.storage.set('company', this.company);
    this.getKeyword();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  getKeyword(){
    console.log("llega");
    var company_id = this.company.company_id_dexga;
    this.storage.get(this.constants.COMPANY).then(res => {
      this._peti.getKeywords(res, company_id).subscribe(result => {
        this.keywords = result;
        this.ready = true;
      });
    });
  }

  getCita(){
    return this.modalCtrl.create({
      component: AppointmentCreateModalComponent
    }).then(modal => {
        modal.present();
        modal.onDidDismiss().then(data => {
            if (data.data == null) {
                return;
            }
        });
    });
  }

  getPedido(){
    return this.modalCtrl.create({
      component: OrderModalComponent
    }).then(modal => {
        modal.present();
        modal.onDidDismiss().then(data => {
            if (data.data == null) {
                return;
            }
        });
    });
  }

}
