import {NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import {MyCalendarComponent} from "../my-calendar/my-calendar.component";
import {FullCalendarModule} from "@fullcalendar/angular";
import {CreateModalComponent} from "../my-calendar/create-modal/create-modal.component";
import {IonicModule} from "@ionic/angular";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import { LOCALE_ID } from '@angular/core';
import {Ng2SearchPipeModule} from "ng2-search-filter";
import {EditModalComponent} from "../my-calendar/edit-modal/edit-modal.component";
import {CustomerCalendarComponent} from "../customer-calendar/customer-calendar.component";
import {PriorityTimeCreateModalComponent} from "../pages/customer/modal/priority-time-create-modal/priority-time-create-modal.component";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {PriorityTimeEditModalComponent} from "../pages/customer/modal/priority-time-edit-modal/priority-time-edit-modal.component";
import {WarningModalComponent} from "../my-calendar/warning-modal/warning-modal.component";
import {InfoModalComponent} from "../my-calendar/info-modal/info-modal.component";
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AppointmentCreateModalComponent } from '../pages/customer/modal/appointment-create-modal/appointment-create-modal.component';
import {HeaderModule} from 'src/app/modules/header/header.module';
import {MobileHeaderModule} from '../modules/mobile-header/mobile-header.module';
import { FcmService } from '../services/fcm/fcm.service';
import { CompanyModalComponent } from '../pages/customer/modal/company-modal/company-modal.component';
import { OrderModalComponent } from '../pages/customer/modal/order-modal/order-modal.component';
import { ProfileComponent } from '../pages/customer/modal/profile/profile.component';
import { PriorityTimeComponent } from '../pages/customer/modal/priority-time/priority-time.component';
import { AddressComponent } from '../pages/customer/modal/address/address.component';
import { AddAddressComponent } from '../pages/customer/modal/add-address/add-address.component';
import { EditAddressComponent } from '../pages/customer/modal/edit-address/edit-address.component';
import { OrderInformationComponent } from "../pages/customer/modal/order-information/order-information.component";
import { ProfileInformationComponent } from "../pages/customer/modal/profile-information/profile-information.component";
import { EditAppointmentModalComponent } from "../pages/company/modal/edit-appointment-modal/edit-appointment-modal.component";
import { EditOrderModalComponent } from "../pages/company/modal/edit-order-modal/edit-order-modal.component";

@NgModule({
    declarations: [
        MyCalendarComponent,
        CreateModalComponent,
        EditModalComponent, 
        CustomerCalendarComponent,
        PriorityTimeCreateModalComponent, 
        PriorityTimeEditModalComponent,
        WarningModalComponent,
        AppointmentCreateModalComponent,
        CompanyModalComponent,
        OrderModalComponent,
        InfoModalComponent,
        ProfileComponent,
        PriorityTimeComponent,
        AddressComponent,
        AddAddressComponent,
        EditAddressComponent,
        OrderInformationComponent,
        ProfileInformationComponent,
        EditAppointmentModalComponent,
        EditOrderModalComponent
    ],
    imports: [
        FullCalendarModule,
        IonicModule,
        FormsModule,
        ReactiveFormsModule, 
        CommonModule, 
        Ng2SearchPipeModule,
        MatSlideToggleModule,
        MatExpansionModule,
        MatTooltipModule,
        HeaderModule,    
        MobileHeaderModule,
    ],
    providers:[{ provide: LOCALE_ID, useValue: "es-ES" }, FcmService],
    exports:[
        MyCalendarComponent, 
        CustomerCalendarComponent,
        PriorityTimeCreateModalComponent, 
        PriorityTimeEditModalComponent, 
        AppointmentCreateModalComponent,
        CompanyModalComponent,
        OrderModalComponent,
        ProfileComponent,
        PriorityTimeComponent,
        AddressComponent,
        AddAddressComponent,
        EditAddressComponent,
        OrderInformationComponent,
        ProfileInformationComponent,
        EditAppointmentModalComponent,
        EditOrderModalComponent
    ]
})
export class CustomComponentsModule {
}
