import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminApiService} from 'src/app/services/admin/admin-api.service';

//import { FCM } from '@ionic-native/fcm/ngx';

import {Chart} from 'chart.js';

@Component({
    selector: 'app-admin-dashboard',
    templateUrl: './admin-dashboard.page.html',
    styleUrls: ['./admin-dashboard.page.scss'],
})
export class AdminDashboardPage implements OnInit {
    @ViewChild('customersCanvas', {static: true}) customersCanvas;
    @ViewChild('companiesCanvas', {static: true}) companiesCanvas;
    @ViewChild('appointmentsCanvas', {static: true}) appointmentsCanvas;
    @ViewChild('doughnutCanvas', {static: true}) doughnutCanvas;

    public customers: any;
    public customers_active: any;
    public companies: any;
    public companies_active: any;
    public appointments: any;
    public appointments_inactive: any;
    public loading: boolean;

    public companiesChart: Chart;
    public appointmentsChart: Chart;
    customersChart: Chart;

    constructor(private _peti: AdminApiService,
                // private fcm : FCM
                ) {}

    ngOnInit() {
        // this.fcm.getToken().then(token => {
        //     //backend.registerToken(token);
        //     console.log("TOKEN" + token);
        //     debugger;
        // });
    }

    getKPIS() {
        this._peti.getKPIS().subscribe(result => {
            this.customers = result['customers'];
            this.customers_active = result['customers_active'];
            this.companies = result['companies'];
            this.companies_active = result['companies_active'];
            this.appointments = result['appointments'];
            this.appointments_inactive = result['appointments_inactive'];
            this.loading = false;
        });
    }

    ionViewDidEnter() {
        this._peti.getKPIS().subscribe(result => {
            this.customers = result['customers'];
            this.customers_active = result['customers_active'];
            this.companies = result['companies'];
            this.companies_active = result['companies_active'];
            this.appointments = result['appointments'];
            this.appointments_inactive = result['appointments_inactive'];
            this.initCustomersChart();
            this.initAppointmentsChart();
            this.initCompanyChart();
            this.loading = false;
        });

    }

    initCustomersChart() {
        this.customersChart = new Chart(this.customersCanvas.nativeElement, {
            type: "doughnut",
            data: {
                labels: [" Clientes Inactivos", " Clientes activos"],
                datasets: [
                    {
                        label: 'clientes',
                        data: [(this.customers - this.customers_active), this.customers_active],
                        backgroundColor: [
                            'rgba(177,177,177,0.8)',
                            'rgba(42,168,255,0.8)',
                        ],
                        hoverBackgroundColor: ['rgba(147,147,147,0.5)', '#36A2EB']
                    }
                ]
            }
        });
    }

    initCompanyChart() {
        this.companiesChart = new Chart(this.companiesCanvas.nativeElement, {
            type: "doughnut",
            data: {
                labels: ['Tiendas inactivas' , 'Tiendas activas' ],
                datasets: [
                    {
                        label: 'tiendas',
                        data: [(this.companies - this.companies_active), this.companies_active],
                        backgroundColor: [
                            'rgba(177,177,177,0.8)',
                            'rgba(223,54,235,0.8)'
                        ],
                        hoverBackgroundColor: [ 'rgba(147,147,147,0.5)', '#b629f3']
                    }
                ]
            }
        });
    }

    initAppointmentsChart() {
        this.appointmentsChart = new Chart(this.appointmentsCanvas.nativeElement, {
            type: 'doughnut',
            data: {
                labels: ['Citas inactivas', 'Citas activas'],
                datasets: [
                    {
                        label: 'citas del último mes',
                        data: [(this.appointments_inactive), (this.appointments)],
                        backgroundColor: [
                            'rgba(186,186,186,0.8)',
                            'rgba(125,255,15,0.8)',
                        ],
                        hoverBackgroundColor: ['rgba(147,147,147,0.9)', '#86ff30']
                    }
                ]
            }
        });
    }

}
