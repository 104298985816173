import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Constants } from 'src/app/services/constants';
import { CustomerApiService } from 'src/app/services/customer/customer-api.service';
import { AddAddressComponent } from '../add-address/add-address.component';
import { EditAddressComponent } from '../edit-address/edit-address.component';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {

  public id;
  public ready = false;
  public address;

  constructor(
    public modalController: ModalController,
    private storage: Storage,
    private constants: Constants,
    private customerService: CustomerApiService,
  ) { }

  ngOnInit() {
    this.getAddress();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  getAddress() {
    this.storage.get(this.constants.CUSTOMER).then(res => {
      this.customerService.getAddress(res).subscribe(result => {
        this.address = result;
        this.ready = true;
        console.log(result);
        console.log(this.ready);
      })
    });
  }

  openAddAddress(){
    return this.modalController.create({
      component: AddAddressComponent
    }).then(modal => {
        modal.present();
        modal.onDidDismiss().then(data => {
          this.getAddress();
            if (data.data == null) {
                return;
            }
        });
    });
  }

  editAddress(){
    return this.modalController.create({
      component: EditAddressComponent
    }).then(modal => {
        modal.present();
        modal.onDidDismiss().then(data => {
          this.getAddress();
            if (data.data == null) {
                return;
            }
        });
    });
  }

}
