import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { CustomerApiService } from 'src/app/services/customer/customer-api.service';

@Component({
  selector: 'app-order-information',
  templateUrl: './order-information.component.html',
  styleUrls: ['./order-information.component.scss'],
})
export class OrderInformationComponent implements OnInit {

  order;
  ready = false;

  constructor(
    public modalController: ModalController,
    private storage: Storage,
    private _peti: CustomerApiService,
  ) { }

  ngOnInit() {
    this.getOrder();
  }

  closeModal() {
    this.modalController.dismiss();
  }

  getOrder(){
    this.storage.get('order').then(res => {
      this.order = res;
      this.ready = true;
      console.log(res)
    });
  }
  
  cancelOrder(){
    var data = {
      accept : "0"
    }
    this._peti.cancelOrder(this.order['order_id'], data).subscribe(result => {
      console.log(result);
    });
  }
}
