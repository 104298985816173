import {Injectable} from '@angular/core';
import {DataService} from './data.service';
import {ActivatedRouteSnapshot} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ResolverService {

    constructor(
        private datasService: DataService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        let id = route.paramMap.get('contact');
        return this.datasService.getData(id);
    }

}
