import {Injectable} from '@angular/core';
import {Constants} from '../constants';
import {HttpClient} from '@angular/common/http';
import {ContactBook} from 'src/app/models/company/contactbook';

@Injectable({
    providedIn: 'root'
})
export class ContactBookApiService {

    constructor(
        private constants: Constants,
        public http: HttpClient
    ) {
    }

    getMyContactBook(companyId) {
        return this.http.get<ContactBook[]>(this.constants.API_ROUTE + 'companies/' + companyId + '/contacts');
    }

    updateContact(request, phone, companyId) {
        return this.http.patch(this.constants.API_ROUTE + 'companies/' + companyId + '/contacts/' + phone, request);
    }

    insertContact(request) {
        return this.http.post(this.constants.API_ROUTE + 'companies/' + request.company_id + '/contacts', request);
    }

    getContacts(companyId) {
        return this.http.get<ContactBook[]>(this.constants.API_ROUTE + 'companies/' + companyId + '/contacts');
    }

    getFilteredContacts(companyId, filter){
        return this.http.get<ContactBook[]>(this.constants.API_ROUTE + 'companies/' + companyId + '/contacts?filter=' + filter);
    }

    getContactByPhone(request) {
        return this.http.get<ContactBook[]>(this.constants.API_ROUTE + 'companies/' + request.company_id + '/contacts/' + request.phone);
    }

    existContactByPhone(phone){
        return this.http.get(this.constants.API_ROUTE + 'customers/phones/' + phone);
    }

}
