import { Component, OnInit } from '@angular/core';
import { CustomerApiService } from 'src/app/services/customer/customer-api.service';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.page.html',
  styleUrls: ['./sale.page.scss'],
})
export class SalePage implements OnInit {

  public flashs = [];
  public ready = false;
  public keyword = [];
  public index = 0;

  constructor(
    private _peti: CustomerApiService,
  ) { }

  ngOnInit() {
    this.getFlash();
  }
  
  getFlash(){
    this._peti.getAllFlash().subscribe(result => {
      this.flashs = result;
      console.log(result);
      for (let index = 0; index < this.flashs.length; index++) {
        var price = this.flashs[index]["price"];
        var discount = this.flashs[index]["discount"];
        this.flashs[index]["newPrice"] = (price - ((discount * price) / 100)).toFixed(2);
        if (new Date(this.flashs[index]["finish"]) > new Date() && new Date(this.flashs[index]["start"]) < new Date()) {
          this.flashs[index]["available"] = "1";
        } else {
          this.flashs[index]["available"] = "0";
        }
        if (this.flashs[index]["photo"] != null) {
          this.flashs[index]["photo"] = 'data:image/jpg;base64,'+ this.flashs[index]["photo"];
        } else {
          this.flashs[index]["photo"] = "../../../../assets/images/logo_AMES.jpg"
        }

      }
      console.log(this.flashs);
      this.ready = true;
    }, err => {
        this.flashs = null;
    });

  }
}
