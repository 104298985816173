import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CompanyMenu {
    returnMenu() {
        const appPages = [
            {
                title: 'Mis citas',
                url: '/tienda/principal',
                icon: 'time'
            },
            // {
            //     title: 'Dar Cita',
            //     url: '/tienda/buscar',
            //     icon: 'ellipsis-horizontal-circle'
            // },
            {
                title: 'Mis clientes',
                url: '/tienda/clientes',
                icon: 'people'
            },
            // {
            //     title: 'Agendas',
            //     url: '/tienda/agendas',
            //     icon: 'ellipsis-horizontal-circle'
            // },
            {
                title: 'Notificaciones',
                url: '/tienda/notificaciones',
                icon: 'notifications'
            },
            {
                title: 'Configuración',
                url: '/tienda/cuenta',
                icon: 'create'
            },

        ];

        return appPages;
    }
}
