import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {Storage} from "@ionic/storage";
import {Constants} from "../services/constants";
import {switchMap} from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private storage: Storage, private constants: Constants) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(this.storage.get(this.constants.TOKEN_KEY))
            .pipe(
                switchMap(token => {
                    if (token != null) {

                    }
                    // console.log(token)
                    const headers = req.headers
                        .set('Authorization', 'Bearer ' + token);
                    const requestClone = req.clone({
                        headers
                    });
                    return next.handle(requestClone);
                })
            );
    }
}
