import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AdminMenu {

    returnMenu() {
        let appPages = [
            {
                title: 'Principal',
                url: '/admin',
                icon: 'home'
            },
            {
                title: 'Clientes',
                url: '/admin/cliente',
                icon: 'people'
            },
            {
                title: 'Tiendas',
                url: '/admin/tienda',
                icon: 'business'
            }
        ]

        return appPages;
    }
}