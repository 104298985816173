import {Injectable} from '@angular/core';
import {Constants} from '../constants';
import {HttpClient} from '@angular/common/http';
import {Storage} from "@ionic/storage";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CompanyApiService {
    private id;

    constructor(
        private constants: Constants,
        public http: HttpClient,
        private storage: Storage
    ) {
    }

    //TODO: poner bien el método
    getMyId(token) {
        return this.http.get(this.constants.API_ROUTE + "companies/id", {
            
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    getMyInfo(token, id) {
        return this.http.get(this.constants.API_ROUTE + "companies/" + id, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
    }

    editCompany(companyId, data): Observable<any> {
        return this.http.patch(this.constants.API_ROUTE + 'companies/' + companyId, data);
    }

    getNotification(userId, notification){
        return this.http.get(`${this.constants.API_ROUTE}users/${userId}/notifications`, notification);
    }
    
    editNotification(notificationId, notification){
        return this.http.patch(`${this.constants.API_ROUTE}notifications/${notificationId}`,notification);
    }

}
