import {Injectable, NgZone} from '@angular/core';
import {
    Plugins,
    PushNotification,
    PushNotificationToken,
    PushNotificationActionPerformed,
    Capacitor, 
    NotificationChannel,
} from '@capacitor/core';

import {Router} from '@angular/router';
import {Storage} from '@ionic/storage';
import { Constants } from '../constants';
import { BehaviorSubject } from 'rxjs';

const {PushNotifications, LocalNotifications} = Plugins;

@Injectable({
    providedIn: 'root'
})
export class FcmService {
    notificationChannel: any;
    fcmToken: BehaviorSubject<any>;
    constructor(
        private router: Router,
        private storage: Storage,
        private constants: Constants,
        private zone: NgZone) 
    {
        this.fcmToken = new BehaviorSubject<any>(null);
    }

    initPush() {
        if (Capacitor.platform !== 'web') {
            this.registerPush();
        }
    }

    private registerPush() {
        PushNotifications.requestPermission().then((permission) => {
            if (permission.granted) {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register().then(() => console.log('PushNotifications registered'));
                this.notificationChannel = LocalNotifications.createChannel({
                    id: 'default',
                    name: 'default',
                    importance: 5,
                    visibility: 1
                });
            } else {
                // No permission for push granted
            }
        });

        PushNotifications.addListener(
            'registration',
            (token: PushNotificationToken) => {
                this.storage.set(this.constants.DEVICE_TOKEN, token.value);
                this.setFcmToken(token.value);
                console.log('My token: ' + JSON.stringify(token));
            }
        );

        PushNotifications.addListener('registrationError', (error: any) => {
            console.log('Error: ' + JSON.stringify(error));
        });

        PushNotifications.addListener(
            'pushNotificationReceived',
            async (notification: PushNotification) => {
                console.log('Push received: ' + JSON.stringify(notification));

                await LocalNotifications.schedule({
                    notifications: [
                        {
                            id: 1,
                            title: notification.title,
                            body: notification.body,
                            channelId: 'default'
                        }
                    ]
                });
            }
        );

        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (notification: PushNotificationActionPerformed) => {
                // console.log("La notificación me redirecciona a : ")
                // this.router.navigateByUrl('directory');
            }
        );
    }

    setFcmToken(token) {
        this.fcmToken.next(token);
    }

    getFcmToken() {
        return this.fcmToken.asObservable();
    }
}
