import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CustomerMenu {
    returnMenu() {
        const appPages = [
            {
                title: 'Mis citas',
                url: '/cliente',
                icon: 'time'
            },
            {
                title: 'Mis pedidos',
                url: '/cliente/pedidos',
                icon: 'cart'
            },
            {
                title: 'Comercio Local',
                url: '/cliente/comercio',
                icon: 'bag'
            },
            {
                title: 'Ofertas flash',
                url: '/cliente/flash',
                icon: 'color-wand'
            },
            // {
            //     title: 'Agenda',
            //     url: '/cliente/citas',
            //     icon: 'ellipsis-horizontal-circle'
            // },
            // {
            //     title: 'Horario preferencia',
            //     url: '/cliente/horario-preferencia',
            //     icon: 'ellipsis-horizontal-circle'
            // },
            // {
            //     title: 'Notificaciones',
            //     url: '/cliente/directorio',
            //     icon: 'ellipsis-horizontal-circle'
            // }
        ];

        return appPages;
    }
}
