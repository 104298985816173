import {FormControl} from '@angular/forms';

export class PhoneValidator {

    static isValid(fc: FormControl) {

        if (!(/^\d{9}$/.test(fc.value))) {
            return ({phone: "Introduzca un teléfono válido."});
        } else if (fc.value.substring(0, 1) < 5) {
            return ({phone: "Introduzca un teléfono válido."});
        } else {
            return (null);
        }

    }

}