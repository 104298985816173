import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {IonicStorageModule} from '@ionic/storage';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {HeaderModule} from './modules/header/header.module';
import {MobileHeaderModule} from './modules/mobile-header/mobile-header.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatAutocompleteModule, MatButtonModule, MatInputModule} from '@angular/material';
import {MatFormFieldModule} from '@angular/material/form-field';
import {AuthInterceptor} from './interceptors/auth-interceptor';
import {IndexComponent} from './index/index.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {FullCalendarModule} from '@fullcalendar/angular';
import {CustomComponentsModule} from './custom/custom-components.module';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDividerModule} from '@angular/material/divider';
import {PopoverConfigComponent} from './popover-config/popover-config.component';
import {EditScheduleModalComponent} from './pages/admin/edit-company/schedules/modals/edit-schedule-modal/edit-schedule-modal.component';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {Device} from '@ionic-native/device/ngx';
import {HeaderComponent} from './components/header/header.component';
import {SuperTabsModule} from '@ionic-super-tabs/angular';
import {CommonModule} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';

@NgModule({
    declarations: [AppComponent, IndexComponent, PopoverConfigComponent, EditScheduleModalComponent, HeaderComponent],
    entryComponents: [],
    imports: [
        MatTooltipModule,
        BrowserModule,
        HttpClientModule,
        ReactiveFormsModule,
        IonicModule.forRoot(),
        FormsModule,
        AppRoutingModule,
        IonicStorageModule.forRoot(),
        HeaderModule,
        MobileHeaderModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatFormFieldModule,
        MatDividerModule,
        MatStepperModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        Ng2SearchPipeModule,
        FullCalendarModule,
        CustomComponentsModule,
        MatButtonModule,
        CommonModule,
        SuperTabsModule.forRoot()
    ],
    providers: [
        StatusBar,
        SplashScreen,
        AppVersion,
        Device,
        FileTransfer,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    ],
    bootstrap: [AppComponent],
    exports: [
        HeaderComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
