import {Component, Input, OnInit} from '@angular/core';
import {ModalController, ToastController} from "@ionic/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactBook} from "../../models/company/contactbook";
import {AppointmentApiService} from "../../services/company/appointment-api.service";
import {Appointment} from "../../models/admin/Appointment";
import * as moment from 'moment';
import {ContactBookApiService} from "../../services/company/contact-book-api.service";
import {AddCustomerComponent} from "../../pages/company/modals/add-customer/add-customer.component";
import {Storage} from "@ionic/storage";
import {Constants} from "../../services/constants";

@Component({
    selector: 'app-create-modal',
    templateUrl: './create-modal.component.html',
    styleUrls: ['./create-modal.component.scss'],
})
export class CreateModalComponent implements OnInit {

    @Input() dateStart;
    @Input() dateEnd;
    @Input() schedule;
    @Input() schedules;
    @Input() contacts;
    @Input() contact;

    @Input() companyId;

    contactsFiltered;
    searchedKeyword: string = "";
    formGroup: FormGroup;
    dateTitle;
    client;
    selectedContact = false;

    loading: boolean = false;

    constructor(private modalController: ModalController,
                private fb: FormBuilder,
                private appointmentApiService: AppointmentApiService,
                private toastController: ToastController,
                private contactBookApiService: ContactBookApiService,
                private storage: Storage,
                private constants: Constants) {
    }

    ngOnInit() {
        if (this.contact != null) {
            let c = null;
            for (c of this.contacts) {
                if (c.customer_id_dexga == this.contact) {
                    this.client = c;
                }
            }
        }
        this.contactsFiltered = this.contacts;

        var dia = new Date (this.dateStart).toString().substring(8,10);
        var mes = new Date (this.dateStart).toString().substring(4,7);
        var diaString = new Date (this.dateStart).toString().substring(0,3);
        if (diaString == "Mon") { diaString = "LUN"}
        if (diaString == "Tue") { diaString = "MAR"}
        if (diaString == "Wed") { diaString = "MIE"}
        if (diaString == "Thu") { diaString = "JUE"}
        if (diaString == "Fri") { diaString = "VIE"}
        if (diaString == "Sat") { diaString = "SAB"}
        if (diaString == "Sun") { diaString = "DOM"}
        if (mes == "Jan") { mes = "enero"}
        else if (mes == "Feb") { mes = "febrero"}
        else if (mes == "Mar") { mes = "marzo"}
        else if (mes == "Apr") { mes = "abril"}
        else if (mes == "May") { mes = "mayo"}
        else if (mes == "Jun") { mes = "junio"}
        else if (mes == "Jul") { mes = "julio"}
        else if (mes == "Aug") { mes = "agosto"}
        else if (mes == "Sep") { mes = "septiembre"}
        else if (mes == "Oct") { mes = "octubre"}
        else if (mes == "Nov") { mes = "noviembre"}
        else if (mes == "Dec") { mes = "diciembre"}
        this.dateTitle = diaString + ' ' + dia + ' ' + mes;
        
        this.dateStart = moment(this.dateStart);
        this.dateEnd = moment(this.dateEnd);

        this.formGroup = this.fb.group({
            from: [this.dateStart.toISOString(), [Validators.required]],
            to: [this.dateEnd.toISOString(), [Validators.required]],
            description: [''],
            schedule: [this.schedule?.id, [Validators.required]],
            need_confirmation: [false]
        });

    }

    checkPhone(contact) {
        return contact.phone.includes(this.searchedKeyword) || contact.alias.toLowerCase().includes(this.searchedKeyword.toLowerCase());
    }

    updateTelfFilter(event) {
        this.client = null;
        this.selectedContact = false;
        this.searchedKeyword = event.detail.value;
        // for (let index = 0; index < this.contactsFiltered.length; index++) {
        //     this.contactsFiltered[index].alias = (this.contactsFiltered[index].alias).toLowerCase();
        // }
        this.contactsFiltered = this.contacts.filter(contact => this.checkPhone(contact));
    }


    setClient(client) {
        if (client == null) return;
        //console.log(this.client);
        this.client = client;
    }

    createAppointment() {

        let appointment = {
            schedule_id: this.formGroup.value.schedule,
            customer_id: this.client.customer_id_dexga,
            comment: this.formGroup.value.description,
            start: this.formGroup.value.from,
            end: this.formGroup.value.to,
            need_confirmation: this.formGroup.value.need_confirmation,
            alias: this.client.alias
        }

        this.loading = true;
        this.appointmentApiService.createAppointment(this.companyId, this.schedule.id, appointment).subscribe(result => {
            this.presentToast("Cita creada con éxito", "primary");
            //console.log("resultado")
            //console.log(result)
            result['alias']= this.client.alias;
            this.modalController.dismiss({"appointment": result});
        }, error => {
            if (error.status == 400) {
                this.presentToast("El cliente ya tiene una cita para esas horas", "danger");
            } else {
                console.log(error)
                this.presentToast("Ha ocurrido un error", "danger");
            }

            //console.log(error);
        }, () => {
            this.loading = false;
        });

        this.storage.get(this.constants.USER_ID).then(res => {
            //Creamos la notificación
            let notification = {
                user_type: 'customer',
                user_id: res,
                user_id_send: appointment.customer_id,
                title: 'Creación de cita',
                seen: 0,
                description: 'Cita añadida para el día ' + (appointment.start).substring(8,10) + ' del ' + (appointment.start).substring(5,7) + ' de ' + (appointment.start).substring(0, 4) +  ' desde las ' +
                (appointment.start).substring(11,16) + ' hasta las ' + (appointment.end).substring(11, 16) + '.',
                alias: appointment.alias,
                start: new Date(appointment.start),
                finish: new Date(appointment.end),
                phone: this.client.phone
            }

            console.log(notification);

            this.appointmentApiService.createNotification(res, notification).subscribe(result => {
                console.log(result);
            })
        });    
    }

    selectContact(contactSelected){
        this.selectedContact = true;
        this.client = contactSelected;
    }

    dismiss() {
        this.modalController.dismiss({
            'dismissed': true
        });
    }

    presentToast(msg: string, color: string) {
        this.toastController.create({
            color,
            message: msg,
            duration: 4000
        }).then(toast => toast.present());
    }

    openInsertModal() {
        const promise = this.modalController.create({
            component: AddCustomerComponent,
            backdropDismiss: false,
            componentProps: {
                phone: this.searchedKeyword
            }
        });


        promise.then(modal => {
            modal.present();
            modal.onWillDismiss().then(data => {

                if (data !== undefined) {
                    this.insertContact(data.data);
                } else {
                }

            });
        });

    }


    insertContact(data) {
        // Recupero el company_id y se lo añado a data, ya que necesito utilizarlo en la petición
        data.company_id = this.companyId;

        //console.log(data);

        this.contactBookApiService.insertContact(data).subscribe(result => {
                this.presentToast('Cliente creado con éxito', 'success');
                //console.log(result);
                this.contacts.push(result);
                this.contactsFiltered.push(result);
                this.client = result;
            },
            err => {
                console.log(err)
                if (err.status == 400 && err.error.msg === 'duplicate') {
                    this.presentToast('El cliente ya existe en su agenda.', 'danger');
                } else {
                    this.presentToast('Se ha producido un error. Inténtelo mas tarde.', 'danger');
                }
            });

    }
}
