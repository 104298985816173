import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalController, NavParams} from '@ionic/angular';
import {TrimValidator} from '../../../../validation/trim.validation';
import {PhoneValidator} from '../../../../validation/phone.validation';

@Component({
    selector: 'app-add-customer',
    templateUrl: './add-customer.component.html',
    styleUrls: ['./add-customer.component.scss'],
})
export class AddCustomerComponent implements OnInit {
    contactBookForm: FormGroup;
    isSubmitted = false;

    constructor(
        private modalCtrl: ModalController,
        public formBuilder: FormBuilder,
        private params: NavParams
    ) {
    }

    get errorControl() {
        return this.contactBookForm.controls;
    }

    ngOnInit() {
        this.contactBookForm = this.formBuilder.group({
            name: ['', Validators.compose([TrimValidator.isValid, Validators.maxLength(50), Validators.required])],
            phone: [this.params.get('phone'), Validators.compose([PhoneValidator.isValid, Validators.minLength(9), Validators.required])],
            email: ['', Validators.email],
            comment: ['', Validators.compose([Validators.maxLength(200)])]
        });
    }

    key() {
        this.isSubmitted = true;
    }


    submitForm() {
        this.isSubmitted = true;
        if (!this.contactBookForm.valid) {
            return false;
        } else {
            this.modalCtrl.dismiss({
                alias: this.contactBookForm.value.name.trim(),
                phone: this.contactBookForm.value.phone.trim(),
                email: this.contactBookForm.value.email.trim(),
                comment: this.contactBookForm.value.comment
            });
        }
    }

    cancel() {
        this.modalCtrl.dismiss();
    }
}
