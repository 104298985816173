import {Component, OnInit} from '@angular/core';
import {AlertController, ModalController, Platform, PopoverController} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AdminDashboardPage} from './pages/admin/admin-dashboard.page';
import {CustomerDashboardPage} from './pages/customer/customer-dashboard/customer-dashboard.page';
import {CompanyDashboardPage} from './pages/company/company-dashboard/company-dashboard.page';
import {IndexComponent} from './index/index.component';
import {PopoverConfigComponent} from './popover-config/popover-config.component';
import {Router} from '@angular/router';
import {AuthService} from './services/auth.service';
import {CustomerMenu} from './pages/customer/customer-menu';
import {CompanyMenu} from './pages/company/company-menu';
import {AdminMenu} from './pages/admin/admin-menu';
import {AppVersion} from '@ionic-native/app-version/ngx';
import {Storage} from "@ionic/storage";

import { FcmService } from './services/fcm/fcm.service';
import { Constants } from './services/constants';
import { CompanyApiService } from './services/company/company-api.service';
import { ProfileComponent } from './pages/customer/modal/profile/profile.component';


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    providers: [AdminDashboardPage, CustomerDashboardPage, CompanyDashboardPage, IndexComponent]
})

export class AppComponent implements OnInit {
    public appPages = [];
    public selectedIndex = 0;

    public isAdmin: boolean;
    public isUser: boolean;
    public isCompany: boolean;
    public notifications;
    public numberNotifications: any;
    public getNotiInterval: any;
    app_version: any;
    api_version: any;
    blocked: any;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private popoverController: PopoverController,
        private router: Router,
        private auth: AuthService,
        private customerMenu: CustomerMenu,
        private companyMenu: CompanyMenu,
        private adminMenu: AdminMenu,
        private appVersion: AppVersion,
        private alertCtrl: AlertController,
        private fcmService: FcmService,
        private storage: Storage,
        private constants: Constants,
        private companyApiService: CompanyApiService,
        private modalCtrl: ModalController
    ) {
        // obtendo la carpeta en la que accede y en función de eso le asigno las rutas
        this.router.events.subscribe((val: any) => {
            this.auth.getRole().then(role => {
                this.appPages = this.fillMenu(role);

                if (val.url !== undefined) {
                    this.selectedIndex = this.fillIndex(val.url);
                }

                this.isAdmin = role === 'admin';
                this.isUser = role === 'customer';
                this.isCompany = role === 'company';
            });
        });

        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.checkVersion();
            this.statusBar.styleBlackOpaque();
            this.splashScreen.hide();
            this.fcmService.initPush();
        });
    }

    ngOnInit() {
        this.getNotifications();
        this.getNotiInterval = setInterval( () => this.getNotifications(), 5000);
    }

    
  getNotifications(){
    this.storage.get(this.constants.USER_ID).then(res1 => {
      //Creamos la notificación
      this.storage.get(this.constants.COMPANY).then(res2 => {
        this.companyApiService.getNotification(res2, res1).subscribe(result => {
          this.notifications = result;
          this.numberNotifications = 0;
          for (let index = 0; index < this.notifications.length; index++) {
            if (this.notifications[index].seen == 0) {
              this.numberNotifications = this.numberNotifications + 1;
            }
          }
        }, error => {
        })
      });  
    }); 
  }

    async showPop(ev: any) {
        const popover = await this.popoverController.create({
            component: PopoverConfigComponent,
            event: ev
        });
        return await popover.present();
    }

    fillMenu(e) {
        let menu = [
            {
                title: 'Login',
                url: '/login',
                icon: 'person'
            }
        ];
        switch (e) {
            case 'customer':
                menu = this.customerMenu.returnMenu();
                break;

            case 'company':
                menu = this.companyMenu.returnMenu();
                break;

            case 'admin':
                menu = this.adminMenu.returnMenu();
                break;

            default:

                break;
        }

        return menu;
    }

    fillIndex(fullPath) {
        let index;
        const menuType = fullPath.split('/')[1];

        switch (menuType) {
            case 'cliente':
                index = this.customerMenu.returnMenu().findIndex(obj => obj.url === fullPath);
                break;

            case 'tienda':
                index = this.companyMenu.returnMenu().findIndex(obj => obj.url === fullPath);
                break;

            case 'admin':
                index = this.adminMenu.returnMenu().findIndex(obj => obj.url === fullPath);
                break;

            default:
                index = 0;
                break;
        }

        return index;
    }

    checkVersion() {
       this.auth.getVersion().then(value =>{
            this.app_version = this.constants.APP_VERSION;
            this.api_version = value;
            this.blockApp();
        });
    }

    blockApp() {
        if (this.platform.is('android')) {
            if (this.isNewerVersion(this.app_version, this.api_version['Android']['appVersion'])) {
                this.blocked = 0;
            } else {
                this.blocked = 1;
            }
        }

        if (this.platform.is('ios')) {
            if (this.isNewerVersion(this.app_version, this.api_version['IOS']['appVersion'])) {
                this.blocked = 0;
            } else {
                this.blocked = 1;
            }
        }
    }

    isNewerVersion(oldVer, newVer) {
        if(oldVer == newVer){
            return true;
        }
        const oldParts = oldVer.split('.');
        const newParts = newVer.split('.');
        for (var i = 0; i < newParts.length; i++) {
            const a = parseInt(newParts[i]) || 0;
            const b = parseInt(oldParts[i]) || 0;
            if (a < b) {
                return true;
            }
            if (a > b) {
                return false;
            }
        }
        return false;
    }

    async logout() {
        const alert = await this.alertCtrl.create({
            header: 'Cerrar sesión actual',
            buttons: [
                {
                    text: 'Volver',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                    }
                }, {
                    text: 'Cerrar sesión',
                    handler: () => {
                        this.auth.logout();
                    }
                }
            ]
        });
        await alert.present();
    }

    liberate() {
        this.router.navigateByUrl('admin/liberar-ip');
    }

    editUser() {
        if (this.isAdmin) {        
            this.router.navigateByUrl('admin/cuenta');
        }

        if (this.isCompany) {
            this.router.navigateByUrl('tienda/cuenta');
        }

        if(this.isUser){
            this.router.navigateByUrl('cliente/cuenta');
        }
    }

    editUser1(){
        return this.modalCtrl.create({
            component: ProfileComponent
        }).then(modal => {
            modal.present();
            modal.onDidDismiss().then(data => {
                if (data.data == null) {
                    return;
                }
            });
        });
    }
}
