import { Component, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Constants } from 'src/app/services/constants';
import { CustomerApiService } from 'src/app/services/customer/customer-api.service';

@Component({
  selector: 'app-profile-information',
  templateUrl: './profile-information.component.html',
  styleUrls: ['./profile-information.component.scss'],
})
export class ProfileInformationComponent implements OnInit {

  enabled = false;
  ready = false;
  public form;
  data;

  constructor(
    public modalController: ModalController,
    private customerService: CustomerApiService,
    private toast: ToastController,
    private storage: Storage,
    private constants: Constants
  ) { }

  ngOnInit() {
    this.getCustomer();
  }
  
  closeModal() {
    this.modalController.dismiss();
  }

  getCustomer(){
    this.storage.get(this.constants.CUSTOMER).then(res => {
      var data = {
        customer_id : res
      }
      this.customerService.getCustomer('0', data).subscribe(result => {
        this.data = result;
        this.ready = true;
        console.log(this.data);
      })
    });
  }

  edit(){
    this.enabled = true;
  }

  saveData(){
    
    var tlf = (<HTMLInputElement>document.getElementById("telefono")).value;
    var name = (<HTMLInputElement>document.getElementById("nombre")).value;
    var error = false;
    console.log(tlf);
    console.log(name);
    if(tlf.length != 9){
      this.presentToast("El teléfono debe tener 9 números");
      error = true;
    }
    if (name == "" || name == null) {
      this.presentToast("Se necesita introducir un nombre");
      error = true;
    }

    if (!error) {
      var data = {
        phone : tlf,
        name : name
      }

      this.storage.get(this.constants.CUSTOMER).then(res => {
        this.storage.get(this.constants.TOKEN_KEY).then(res1 => {
          this.customerService.editCustomer(res1,res, data).subscribe(result => {
            this.presentToast1("Modificado correctamente");
            this.closeModal();
          })
        });
      });
    } 
  }

  async presentToast(message) {
    const toast = await this.toast.create({
        message,
        duration: 2000,
        color: "danger"
    });
    toast.present();
  }

  async presentToast1(message) {
    const toast = await this.toast.create({
        message,
        duration: 2000,
        color: "success"
    });
    toast.present();
  }

}
