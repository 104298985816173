import {Injectable} from '@angular/core';

import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class Constants {
    public API_ROUTE = environment.apiUrl;
    public TOKEN_KEY = 'jwt-token';
    public USER_ROLE = 'role';
    public COMPANY = 'company_id';
    public CUSTOMER = 'customer_id';
    public ADMIN = 'admin_id';
    public USER_ID = '';
    public TOKEN_CREATE_COMPANY = ',gz4).myJqbgbwDiXMe8td!]S:69ryCZGNM_f5R+U39xtddiN5}444[#p(;h-hi_';
    public TOKEN_CREATE_CUSTOMER = '%j4B5%,EWzq]J2phhN8Nt.gJU3nPd]kd:n_+kUuQkBQtmYURaH3nPum=BL2$9dTZ';
    public COORDS_COMPANY;
    public DEVICE_TOKEN;
    public APP_VERSION = '1.0.14';
    public RELEASE = 'web';
}
