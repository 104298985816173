import { ModalController, AlertController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { PriorityTimeComponent } from '../priority-time/priority-time.component';
import { AddressComponent } from '../address/address.component';
import { ProfileInformationComponent } from '../profile-information/profile-information.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  constructor(
    public modalController: ModalController,
    private alertCtrl: AlertController,
    private auth: AuthService,
  ) { }

  ngOnInit() {}

  closeModal() {
    this.modalController.dismiss();
  }

  async logout() {
    const alert = await this.alertCtrl.create({
        header: 'Cerrar sesión actual',
        buttons: [
            {
                text: 'Volver',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                }
            }, {
                text: 'Cerrar sesión',
                handler: () => {
                  this.modalController.dismiss();
                  this.auth.logout();
                }
            }
        ]
    });
    await alert.present();
  }

  openPriorityTime(){
    return this.modalController.create({
      component: PriorityTimeComponent
    }).then(modal => {
        modal.present();
        modal.onDidDismiss().then(data => {
            if (data.data == null) {
                return;
            }
        });
    });
  }

  openAddress(){
    return this.modalController.create({
      component: AddressComponent
    }).then(modal => {
        modal.present();
        modal.onDidDismiss().then(data => {
            if (data.data == null) {
                return;
            }
        });
    });
  }

  openMyProfile(){
    return this.modalController.create({
      component: ProfileInformationComponent
    }).then(modal => {
        modal.present();
        modal.onDidDismiss().then(data => {
            if (data.data == null) {
                return;
            }
        });
    });
  }

}
